import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  useGetCategoryQuery,
  useGetProfitLossQuery,
  useGetTotalExpenseQuery,
  useGetTotalIncomeQuery,
  useGetUtilityCategoryQuery,
  useMonthlyExpenseQuery,
} from "../../slices/getSlice";
import { fetchDetail_Url } from "../../Helper";
import { useSelector } from "react-redux";
import { Select, Spin } from "antd";
import axios from "axios";
import { useOutletContext } from "react-router-dom";

const Report = () => {
  const [providerType, setProviderType] = useState();
  const [filteredData, setFilteredData] = useState();
  const [incomeType, setIncomeType] = useState();
  const { loading, setLoading } = useOutletContext();
  const [selectedGraphType, setSelectedGraphType] = useState();
  const { data: getCategory } = useGetCategoryQuery("income");
  const { data: getTotalIncome, refetch: refetchIncome } = useGetTotalIncomeQuery();
  const { data: getTotalExpense, refetch: refetchExpense } = useGetTotalExpenseQuery();
  const { data: getProfitLoss, refetch: refetchProfitLoss } = useGetProfitLossQuery();
  const { data: getUtilityCategory = [] } = useGetUtilityCategoryQuery();
  const { data: getGraphData = [] } = useMonthlyExpenseQuery('both');
  const chartRef = useRef(null);
  const currencyGlobal = useSelector(
    (state) => state?.persistedReducer?.currency?.currency_symbol
  );
  const currency = currencyGlobal ? currencyGlobal : "";

  useEffect(() => {
    refetchIncome();
    refetchExpense();
    refetchProfitLoss();
  }, [refetchIncome, refetchExpense, refetchProfitLoss]);

  useEffect(() => {
    if (getUtilityCategory && getUtilityCategory.data) {
      const transformedOptions = getUtilityCategory.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setLoading(false)
      setProviderType(transformedOptions);
    }

    if (getCategory && getCategory.data) {
      const transformedOptions = getCategory.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setIncomeType(transformedOptions);
    }
  }, [getUtilityCategory, getCategory]);

  const handleSelectIncome = (value) => {
    axios.get(fetchDetail_Url + `monthly-expense?type=${selectedGraphType}&category_id=${value}`)
      .then((res) => {
        const newData = res?.data;

        // Update the combinedIncomeExpenseChart data
        setFilteredData(newData);

        // Access the chart instance through a ref (assuming you've set up a ref for HighchartsReact)
        if (chartRef.current) {
          chartRef.current.chart.series[0].setData(newData.map(item => item.income_amount)); // Update income series
          chartRef.current.chart.series[1].setData(newData.map(item => item.expense_amount)); // Update expense series
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSelectExpense = (value) => {
    axios.get(fetchDetail_Url + `monthly-expense?type=${selectedGraphType}&provider_type_id=${value}`)
      .then((res) => {
        const newData = res?.data;

        // Update the combinedIncomeExpenseChart data
        setFilteredData(newData);

        // Access the chart instance through a ref (assuming you've set up a ref for HighchartsReact)
        if (chartRef.current) {
          chartRef.current.chart.series[0].setData(newData.map(item => item.income_amount)); // Update income series
          chartRef.current.chart.series[1].setData(newData.map(item => item.expense_amount)); // Update expense series
        }
      })
      .catch((err) => {
        console.error(err);
      });

  };

  const renderIncomeExpenseData = (key) => {
    if (filteredData && filteredData.length > 0) {
      return filteredData.map(item => item[key]);
    }
    if (getGraphData?.length > 0) {
      return getGraphData.map(item => item[key]);
    }
    return [];
  };

  const incomeChart = {
    chart: {
      type: "column",
    },

    credits: {
      enabled: false,
    },
    title: {
      text: "Total Income by Property",
    },
    plotOptions: {
      series: {
        pointWidth: 50,
      },
      column: {
        colorByPoint: true,
      },
    },
    colors: ["#3A54A5"],
    xAxis: {
      categories: getTotalIncome?.map((property) => property.property_name) || [],
      title: {
        text: "Property Name",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total Income",
      },
      labels: {
        formatter: function () {
          return currency + parseFloat(this.value).toFixed(2);
        },
      },
    },
    series: [
      {
        name: "Total Incomes",
        data: getTotalIncome?.map((property) => parseFloat(property.total_income)) || [],
        color: "#0095FF",
      },
    ],
  };

  const expenseChart = {
    chart: {
      type: "column",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Total Expense by Property",
    },
    plotOptions: {
      series: {
        pointWidth: 50,
      },
      column: {
        colorByPoint: true,
      },
    },
    colors: ["#F47939"],
    xAxis: {
      categories: getTotalExpense?.map((property) => property.property_name) || [],
      title: {
        text: "Property Name",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total Expense",
      },
      labels: {
        formatter: function () {
          return currency + parseFloat(this.value).toFixed(2);
        },
      },
    },
    series: [
      {
        name: "Total Expense",
        data: getTotalExpense?.map((property) => parseFloat(property.total_expense)) || [],
      },
    ],
  };

  const profitLossChart = {
    chart: {
      type: "line",
    },
    title: {
      text: "Profit and Loss by Property",
    },
    xAxis: {
      categories: getProfitLoss?.map((property) => property.property_name) || [],
      title: {
        text: "Property Name",
      },
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "Amount",
      },
      labels: {
        formatter: function () {
          return currency + this.value;
        },
      },
    },
    series: [
      {
        name: "Profit",
        data: getProfitLoss?.map((property) => parseFloat(property.profit)) || [],
        color: "green",
      },
      {
        name: "Loss",
        data: getProfitLoss?.map((property) => parseFloat(property.loss)) || [],
        color: "red",
      },
    ],
  };

  const combinedIncomeExpenseChart = {
    chart: {
      type: "spline",
    },
    title: {
      text: "Monthly Income vs Expense",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: renderIncomeExpenseData('month'), // map months here
      title: {
        text: "Month",
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {

        return 'Total Expense for <b>' + this.x +
          '</b><strong>:</strong> <b>' + this.y.toFixed(2) + '</b>';
      }
    },
    yAxis: {
      title: {
        text: "Total Amount",
      },
      labels: {
        formatter: function () {
          return currency + parseFloat(this.value).toFixed(2);
        },
      },
    },
    series: [
      {
        name: "Total Income",
        data: renderIncomeExpenseData('income_total'), // map income_amount
        color: "green",
      },
      {
        name: "Total Expense",
        data: renderIncomeExpenseData('expense_total'), // map expense_amount
        color: "red",
      },
    ],
  };

  const graphType = [
    { value: 'income', label: 'Income' },
    { value: 'expense', label: 'Expense' },
  ];

  const showType = async (value) => {
    setSelectedGraphType(null);
    setSelectedGraphType(value);
    axios.get(fetchDetail_Url + `monthly-expense?type=${value}`)
      .then((res) => {
        const newData = res?.data;

        // Update the combinedIncomeExpenseChart data
        setFilteredData(newData);

        // Access the chart instance through a ref (assuming you've set up a ref for HighchartsReact)
        if (chartRef.current) {
          chartRef.current.chart.series[0].setData(newData.map(item => item.income_amount)); // Update income series
          chartRef.current.chart.series[1].setData(newData.map(item => item.expense_amount)); // Update expense series
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      {loading && (
        <div className="commanLoaderWrapper">
          <Spin size="large" />
          <h4>Setting things up..</h4>
        </div>
      )}

      <div className="customPadding">
        <div className="mainWrapper">
          <div className="card reportCard mb-4">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="cardTitle">Overall Performance</h3>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-6">
              <div className="card chartCard mb-4">
                <div className="card-body">
                  <HighchartsReact highcharts={Highcharts} options={incomeChart} />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card chartCard mb-4">
                <div className="card-body">
                  <HighchartsReact highcharts={Highcharts} options={expenseChart} />
                </div>
              </div>
            </div>
            <div className="col-md-12 pb-4">
              <div className="card chartCard">
                <div className="card-body">
                  <HighchartsReact highcharts={Highcharts} options={profitLossChart} />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card chartCard mb-4">
                <div className="card-body">
                  <div className="highchart-select d-flex justify-content-end" style={{ marginBottom: '15px' }}>
                    <Select
                      defaultValue={"Select Filter Type"}
                      style={{ width: 150 }}
                      options={graphType}
                      onChange={showType}
                    />
                    {selectedGraphType === 'income' && (
                      <Select
                        defaultValue={"Select Income Type"}
                        style={{ width: 150 }}
                        options={incomeType}
                        onChange={handleSelectIncome}
                      />
                    )}
                    {selectedGraphType === 'expense' && (
                      <Select
                        defaultValue={"Select Provider Type"}
                        style={{ width: 150 }}
                        options={providerType}
                        onChange={handleSelectExpense}
                      />
                    )}
                  </div>
                  <HighchartsReact highcharts={Highcharts} options={combinedIncomeExpenseChart} ref={chartRef} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
