import React, { useEffect, useState } from "react";
import {
	Button,
	Form,
	Input,
	Select,
	Row,
	Col,
	Checkbox,
	Space,
	Upload,
	message,
	Spin
} from "antd";
import { RiContactsLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { HiOutlineTrash } from "react-icons/hi";
import { IoMdAddCircle } from "react-icons/io";
import { FaMinusCircle } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { useDeleteExpenseByIdMutation, useEditExpenseByIdMutation } from "../../slices/apiSlice"
const { Item: FormItem } = Form;

const DynamicForm = ({
	form,
	fields,
	onFinish,
	buttonName,
	onUtilityTypeChange,
	anotherButton,
	saveAndExit,
	loading,
	dynamicfields,
	formType,
	ButtonDisable,
	handleDelete,
	isDeleteSave,
	FormInitialValues = {},
	propertyProvider,
	setChangedValues,
	providerID,
	compnayID
}) => {
	const [formValues, setFormValues] = useState({});
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const location = useLocation()
	const colors = useSelector((state) => state?.persistedReducer?.colors);
	const [deleteExpenseById, { isLoading: isDeleteLoading }] = useDeleteExpenseByIdMutation();
	const [editExpenseById, { isLoading: isEditLoading }] = useEditExpenseByIdMutation();
	const [loader, setLoader] = useState(false)
	const [expenseData, setExpenseData] = useState([
		{ id: Date.now(), category_id: null, amount: "", start_date: "" },
	]);

	let hiddenField = true;

	const handleDropdownVisibleChange = (value) => {
		if (value) {
			setDropdownVisible(true);
		} else {
			setDropdownVisible(false);
		}
	};

	const handleValuesChange = (changedValues, allValues) => {
		if (changedValues?.provider_id) {
			dynamicfields = null;
			if (onUtilityTypeChange) {
				onUtilityTypeChange(changedValues?.provider_id);
			}
		}

		if (allValues) {
			setFormValues(allValues);
		}
	};

	useEffect(() => {
		if (FormInitialValues?.company_id == null) {
			form.resetFields(["company_id"]);
		}
		if (FormInitialValues?.state_id == null) {
			form.resetFields(["state_id"]);
			form.resetFields(["city_id"]);
		}
	}, [FormInitialValues]);

	const mainField = fields?.filter((item) => item.label === "Type of Servies" || item.label === "Utility Company");
	const otherFields = fields?.filter((item) => item.label !== "Type of Servies" && item.label !== "Utility Company");

	hiddenField = propertyProvider ? false : otherFields[0]?.hidden;

	useEffect(() => {
		setLoader(true);

		if (propertyProvider?.expenses) {
			const formattedExpenses = propertyProvider.expenses.map((expense) => ({
				id: expense.id,
				category_id: expense.category_id,
				amount: expense.amount,
				start_date: expense.start_date,
			}));
			setExpenseData(formattedExpenses);
		}

		if (propertyProvider?.company || providerID) {
			let IdValue = propertyProvider !== null && propertyProvider !== undefined && propertyProvider?.company !== undefined ? propertyProvider?.company.id : compnayID;
			form.setFieldsValue({
				company_id: IdValue,
			});
		}

		if (propertyProvider?.provider?.id) {
			form.setFieldsValue({
				provider_id: propertyProvider.provider.id,
			});
		}

		setTimeout(() => {
			if (propertyProvider?.form_fields) {
				Object.entries(propertyProvider.form_fields).forEach(([InputID, value]) => {
					if (value) {
						form.setFieldsValue({ [InputID]: value });
					}
				});
			}
		}, 2000);

		setTimeout(() => {
			setLoader(false);
		}, 2700);
	}, [propertyProvider, form]);

	const handleFinish = (allValues) => {
		const staticValues = {};
		const dynamicValues = {};
		const errors = {};

		expenseData.forEach((row) => {
			const rowErrors = {};
			if (!row.category_id) rowErrors.category_id = "Expense Type is required.";
			if (!row.amount) rowErrors.amount = "Amount is required.";
			if (!row.start_date) rowErrors.start_date = "Start Date is required.";

			if (Object.keys(rowErrors).length > 0) {
				errors[row.id] = rowErrors;
			}
		});

		if (Object.keys(errors).length > 0 && formType === "Add") {
			console.error("Validation Errors:", errors);
			message.warning('Please remove empty fields before submitting.');
			return;
		}

		if (dynamicfields && !propertyProvider) {
			for (const field of allFields) {
				const value = formValues[field?.name];
				if (field?.static) {
					staticValues[field?.name] = value;
				} else {
					dynamicValues[field?.name] = value !== undefined ? value : field?.defaultValue;
				}
			}

			if (mainField?.length > 0 && otherFields?.length > 0) {
				staticValues["expenses"] = expenseData;
			}

			onFinish(staticValues, dynamicValues, formType);
		} else {
			for (const field of allFields) {
				const value = formValues[field?.name];

				if (field?.static) {
					staticValues[field?.name] = value;

					if (field?.name === "provider_id") {
						staticValues[field?.name] = allValues?.provider_id;
					}

					if (field?.name === "company_id") {
						staticValues[field?.name] = allValues?.company_id;
					}
				} else {
					if (field?.name !== "provider_id" && field?.name !== "company_id") {
						dynamicValues[field?.name] =
							value !== undefined ? value : field?.defaultValue;
					}
				}

				if (formType === "add") {
					staticValues["provider_type_id"] =
						propertyProvider?.provider_type_id !== null &&
							propertyProvider?.provider_type_id !== undefined
							? propertyProvider?.provider_type_id
							: location.state.propertyData?.property_typeId;
					staticValues["property_id"] =
						propertyProvider?.property_id !== null &&
							propertyProvider?.property_id !== undefined
							? propertyProvider?.property_id
							: location.state.propertyid;
				}
			}

			for (const key in allValues) {
				if (key !== "provider_id" && key !== "company_id") {
					dynamicValues[key] = allValues[key];
				}
			}

			if (mainField?.length > 0 && otherFields?.length > 0) {
				staticValues["expenses"] = expenseData;
			}

			onFinish(staticValues, dynamicValues, formType);
		}
	};

	const allFields = dynamicfields ? [...mainField, ...dynamicfields, ...otherFields] : [...mainField, ...otherFields];

	const onDelete = (fieldId) => {
		handleDelete(fieldId);
	};

	const renderExpense = () => {
		const addRow = () => {
			setExpenseData([
				...expenseData,
				{ id: Date.now(), category_id: null, amount: "", start_date: "", type: "new" },
			]);
		};
		const removeRow = (id) => {
			setExpenseData(expenseData.filter((row) => row.id !== id));
		};
		const handleSelectChange = (value, id) => {
			setExpenseData(
				expenseData.map((row) =>
					row.id === id ? { ...row, category_id: value } : row
				)
			);
		};
		const handleInputChange = (e, id) => {
			setExpenseData(
				expenseData.map((row) =>
					row.id === id ? { ...row, amount: e.target.value } : row
				)
			);
		};
		const handleDateChange = (e, id) => {
			setExpenseData(
				expenseData.map((row) =>
					row.id === id ? { ...row, start_date: e.target.value } : row
				)
			);
		};

		return (
			<>
				{expenseData.map((row) => (
					<Col key={row.id} span={24}>
						<Row gutter={[16, 16]} align="middle">
							<Col span={7}>
								<Select
									value={row.category_id}
									onChange={(value) => handleSelectChange(value, row.id)}
									placeholder="Select Expense Type"
									className="formControl select-custom "
									options={otherFields[0]?.options}
									style={{ width: "100%" }}
								/>
							</Col>
							<Col span={7}>
								<Input
									type="number"
									value={row.amount}
									onChange={(e) => handleInputChange(e, row.id)}
									placeholder="Enter Amount"
									className="form-control"
									onKeyPress={(e) => {
										if (e.key === "-" || e.key === "e") {
											e.preventDefault();
										}
									}}
									min="0"
								/>
							</Col>

							<Col span={7}>
								<Input
									type="date"
									className="form-control"
									value={row.start_date}
									onChange={(e) => handleDateChange(e, row.id)}
									placeholder="Enter Amount"
									onKeyPress={(e) => {
										if (e.key === "-" || e.key === "e") {
											e.preventDefault();
										}
									}}
									min="0"
								/>
							</Col>
							<Col span={3}>
								{propertyProvider && row?.type !== "new" ? (
									<>
										<div className="edit-del-exp-wraper ms-4">
											{(isDeleteLoading || isEditLoading) ? (
												<Spin />
											) : (
												<>
													<AiFillDelete onClick={() => removeRow(row?.id)} style={{ fontSize: "20px", color: "#ff0000", cursor: "pointer" }} />
												</>
											)}

										</div>
									</>
								) : (
									<Button
										type="danger"
										icon={<FaMinusCircle />}
										onClick={() => removeRow(row.id)}
									>
										Remove
									</Button>
								)}

							</Col>
						</Row>
					</Col>
				))}
				<Button
					type="dashed"
					style={{ width: "100%", marginTop: "16px", backgroundColor: "#f47a3a", color: "#fff" }}
					icon={<IoMdAddCircle />}
					onClick={addRow}
				>
					Add More
				</Button>
			</>
		);
	};

	const checkExistingProviderID = (data, fields, updatedFormValues, setFormValues) => {
		const newFormValues = { ...updatedFormValues };

		if (fields.name === 'provider_id' && fields.provider_id !== data) {
			newFormValues.company_id = null;
		}

		if (fields.name === 'company_id') {
			let providerIDVal = newFormValues?.provider_id !== null && newFormValues?.provider_id !== undefined ? newFormValues?.provider_id : formValues?.provider_id !== null && formValues?.provider_id !== undefined ? formValues?.provider_id : providerID;

			if (newFormValues?.company_id && providerIDVal) {
				setChangedValues({
					company_id: data,
					provider_id: providerIDVal,
				});
			}
		}

		setFormValues(newFormValues);
	};

	const handleFieldChange = (field, value, onUtilityTypeChange, setFormValues) => {
		const updatedFormValues = {
			...formValues,
			[field?.name]: value,
		};

		setFormValues(updatedFormValues);

		if (field?.onChange) {
			field.onChange(value);
		}

		if (field?.name === "provider_id" && onUtilityTypeChange) {
			onUtilityTypeChange(value);
		}

		checkExistingProviderID(value, field, updatedFormValues, setFormValues);
	};

	return (
		<>
			{loader ? (
				<div className="d-flex justify-content-center align-items-center">
					<Spin />
				</div>
			) : (
				<>
					{mainField?.length > 0 && otherFields?.length > 0 ? (
						<Form
							form={form}
							layout="vertical"
							onFinish={handleFinish}
							onValuesChange={handleValuesChange}
							initialValues={FormInitialValues ? FormInitialValues : formValues}
						>
							<Row gutter={16}>
								{mainField.map((field, index) => {
									const isHidden = propertyProvider ? false : field?.hidden
										? typeof field?.hidden === "function"
											? field?.hidden()
											: field?.hidden
										: "";

									const shouldDelete =
										location.pathname === "/property_details" && !field?.static && formType == "add";

									return (
										!isHidden && (
											<>
												<Col
													key={field?.name}
													md={field?.colSpan || 24}
													offset={field?.offset || 0}
												>

													{((field?.component === "input" || !field?.component) && field?.type === "phone") &&
														<>
															<FormItem
																label={field?.label}
																name={field?.name}
																rules={[
																	{
																		required: true,
																		message: "Please enter phone number.",
																	},
																	({ getFieldValue }) => ({
																		validator(_, value) {
																			const initialPhoneNumber = getFieldValue(`${field?.name}`);
																			if (initialPhoneNumber === value) {
																				return Promise.resolve();
																			}
																			if (value && value.length === 12) {
																				return Promise.resolve();
																			}

																			return Promise.reject(new Error("Phone number must be exactly 12 digits."));
																		},
																	}),
																]}
																dependencies={field?.dependencies ? [field?.dependencies] : undefined}
															>
																<Input
																	placeholder={field?.placeholder}
																	defaultValue={field?.defaultValue}
																	className="form-control"
																	prefix={field?.prefix}
																	disabled={field?.disabled || ""}
																	type="text"
																	maxLength={12}
																	onKeyPress={(e) => {
																		if (!/[0-9+]/.test(e.key) || (e.key === "+" && e.target.value.includes("+"))) {
																			e.preventDefault();
																		}
																	}}
																	onPaste={(e) => {
																		const paste = (e.clipboardData || window.clipboardData).getData("text");
																		if (!/^[0-9+]+$/.test(paste) || (paste.indexOf("+") > 0)) {
																			e.preventDefault();
																		}
																	}}
																/>
															</FormItem>
															{shouldDelete ? (
																<span className="deleteIcon">
																	<HiOutlineTrash
																		style={{ cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
																		onClick={!isDeleteSave ? () => {
																			onDelete(field?.id);
																			// form.resetFields([field?.name]); 
																		} : null}
																	/>
																</span>
															) : ''}
														</>
													}

													{((field?.component === "input" || !field?.component) && field?.type !== "phone") &&
														<>
															<FormItem
																label={field?.label}
																name={field?.name}
																rules={field?.rules}
																dependencies={
																	field?.dependencies
																		? [field?.dependencies]
																		: undefined
																}
															>
																{field?.type === "password" ? (
																	<Input.Password
																		placeholder={field?.placeholder}
																		defaultValue={field?.defaultValue}
																		className="form-control"
																		prefix={field?.prefix}
																		disabled={field?.disabled || ""}
																		type={field?.type}
																	/>
																) : (
																	<Input
																		placeholder={field?.placeholder}
																		className="form-control"
																		defaultValue={field?.defaultValue}
																		disabled={field?.disabled || ""}
																		prefix={
																			field?.prefix ? (
																				field?.prefix
																			) : (
																				<RiContactsLine
																					style={{
																						color: colors?.primary || "#F47A3A",
																						fontSize: "25px",
																					}}
																				/>
																			)
																		}
																		type={field?.type}
																	/>
																)}
															</FormItem>
															{shouldDelete ? (
																<span className="deleteIcon">
																	<HiOutlineTrash
																		style={{ cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
																		onClick={!isDeleteSave ? () => {
																			onDelete(field?.id);
																			// form.resetFields([field?.name]); // Remove the field from form validation
																		} : null}
																	/>
																</span>
															) : ''}
														</>

													}
													{field?.component === "select" && (
														<FormItem
															label={field?.label}
															name={field?.name}
															rules={field?.rules}
														>
															<Select
																id={index}
																dropdownRender={(originNode) => (
																	<div
																		style={{
																			padding: "0px",
																			display:
																				field?.name === "category_id" ||
																					field?.name === "frequency" ||
																					field?.name === "provider_id" ||
																					field?.name === "company_id"
																					? "block"
																					: dropdownVisible
																						? "block"
																						: "none",
																		}}
																	>
																		{originNode}
																	</div>
																)}
																className="formControl select-custom "
																disabled={field.disabled || ""}
																showSearch
																onSelect={() => setDropdownVisible(false)}
																onSearch={handleDropdownVisibleChange}
																filterOption={(input, option) =>
																	(option?.label ?? "")
																		.toLowerCase()
																		.includes(input.toLowerCase())
																}
																mode={field.mode || ""}
																optionLabelProp="label"
																placeholder={field?.placeholder}
																options={field?.options}
																suffixIcon={field?.suffixIcon}
																onChange={(value) => handleFieldChange(field, value, onUtilityTypeChange, setFormValues)}
																// onChange={(value) => {
																// 	setFormValues((prevValues) => ({
																// 		...prevValues,
																// 		[field?.name]: value,
																// 	}));
																// 	if (field?.onChange) {
																// 		field?.onChange(value);
																// 	}
																// 	if (
																// 		field?.name === "provider_id" &&
																// 		onUtilityTypeChange
																// 	) {
																// 		onUtilityTypeChange(value);
																// 	}
																// }}
																optionRender={(option) => (
																	<Space>
																		<span role="img" aria-label={option.data.value ? option.data.value : option.value}>
																			{option.data.label ? option.data.label : option.label}
																		</span>
																	</Space>
																)}
															/>
														</FormItem>
													)}
													{field?.component === "checkbox" && (
														<Row justify="space-between">
															<Col span={12}>
																<FormItem name={field?.name} valuePropName="checked">
																	<Checkbox
																		className="rememberMe"
																		onChange={(value) => {
																			setFormValues((prevValues) => ({
																				...prevValues,
																				[field.name]: value,
																			}));
																			if (field?.onChange) {
																				field?.onChange(value);
																			}
																		}}
																	>
																		{field?.label}
																	</Checkbox>
																</FormItem>
															</Col>
															{buttonName === "Sign In" && (
																<Col span={12} className="forgotpassword">
																	<Link
																		to="/forgot-password"
																		style={{ lineHeight: "32px" }}
																		className="forgotLink"
																	>
																		Forgot Password?
																	</Link>
																</Col>
															)}
														</Row>
													)}
												</Col>
											</>
										)
									);
								})}
							</Row>
							<Row gutter={16}>
								{dynamicfields && dynamicfields !== '' && dynamicfields?.map((field, index) => {
									const isHidden = propertyProvider ? false : field?.hidden
										? typeof field?.hidden === "function"
											? field?.hidden()
											: field?.hidden
										: "";

									const shouldDelete =
										location.pathname === "/property_details" && !field?.static && formType == "add";

									return (
										!isHidden && (
											<>
												<Col
													key={field?.name}
													md={field?.colSpan || 24}
													offset={field?.offset || 0}
												>

													{((field?.component === "input" || !field?.component) && field?.type === "phone") &&
														<>
															<FormItem
																label={field?.label}
																name={field?.name}
																rules={[
																	{
																		required: true,
																		message: "Please enter phone number.",
																	},
																	({ getFieldValue }) => ({
																		validator(_, value) {
																			const initialPhoneNumber = getFieldValue(`${field?.name}`);
																			if (initialPhoneNumber === value) {
																				return Promise.resolve();
																			}
																			if (value && value.length === 12) {
																				return Promise.resolve();
																			}

																			return Promise.reject(new Error("Phone number must be exactly 12 digits."));
																		},
																	}),
																]}
																dependencies={field?.dependencies ? [field?.dependencies] : undefined}
															>
																<Input
																	placeholder={field?.placeholder}
																	defaultValue={field?.defaultValue}
																	className="form-control"
																	prefix={field?.prefix}
																	disabled={field?.disabled || ""}
																	type="text"
																	maxLength={12}
																	onKeyPress={(e) => {
																		if (!/[0-9+]/.test(e.key) || (e.key === "+" && e.target.value.includes("+"))) {
																			e.preventDefault();
																		}
																	}}
																	onPaste={(e) => {
																		const paste = (e.clipboardData || window.clipboardData).getData("text");
																		if (!/^[0-9+]+$/.test(paste) || (paste.indexOf("+") > 0)) {
																			e.preventDefault();
																		}
																	}}
																/>
															</FormItem>


															{shouldDelete ? (
																<span className="deleteIcon">
																	<HiOutlineTrash
																		style={{ cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
																		onClick={!isDeleteSave ? () => {
																			onDelete(field?.id);
																			// form.resetFields([field?.name]); 
																		} : null}
																	/>
																</span>
															) : ''}
														</>

													}

													{((field?.component === "input" || !field?.component) && field?.type !== "phone") &&
														<>
															<FormItem
																label={field?.label}
																name={field?.name}
																rules={field?.rules}
																dependencies={
																	field?.dependencies
																		? [field?.dependencies]
																		: undefined
																}
															>
																{field?.type === "password" ? (
																	<Input.Password
																		placeholder={field?.placeholder}
																		defaultValue={field?.defaultValue}
																		className="form-control"
																		prefix={field?.prefix}
																		disabled={field?.disabled || ""}
																		type={field?.type}
																	/>
																) : (
																	<Input
																		placeholder={field?.placeholder}
																		className="form-control"
																		defaultValue={field?.defaultValue}
																		disabled={field?.disabled || ""}
																		prefix={
																			field?.prefix ? (
																				field?.prefix
																			) : (
																				<RiContactsLine
																					style={{
																						color: colors?.primary || "#F47A3A",
																						fontSize: "25px",
																					}}
																				/>
																			)
																		}
																		type={field?.type}
																	/>
																)}
															</FormItem>
															{shouldDelete ? (
																<span className="deleteIcon">
																	<HiOutlineTrash
																		style={{ cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
																		onClick={!isDeleteSave ? () => {
																			onDelete(field?.id);
																			// form.resetFields([field?.name]); // Remove the field from form validation
																		} : null}
																	/>
																</span>
															) : ''}
														</>

													}
													{field?.component === "select" && (
														<FormItem
															label={field?.label}
															name={field?.name}
															rules={field?.rules}
														>
															<Select
																id={index}
																dropdownRender={(originNode) => (
																	<div
																		style={{
																			padding: "0px",
																			display:
																				field?.name === "category_id" ||
																					field?.name === "frequency" ||
																					field?.name === "provider_id" ||
																					field?.name === "company_id"
																					? "block"
																					: dropdownVisible
																						? "block"
																						: "none",
																		}}
																	>
																		{originNode}
																	</div>
																)}
																className="formControl select-custom "
																disabled={field.disabled || ""}
																showSearch
																onSelect={() => setDropdownVisible(false)}
																onSearch={handleDropdownVisibleChange}
																filterOption={(input, option) =>
																	(option?.label ?? "")
																		.toLowerCase()
																		.includes(input.toLowerCase())
																}
																mode={field.mode || ""}
																optionLabelProp="label"
																placeholder={field?.placeholder}
																options={field?.options}
																suffixIcon={field?.suffixIcon}
																onChange={(value) => {
																	setFormValues((prevValues) => ({
																		...prevValues,
																		[field?.name]: value,
																	}));
																	if (field?.onChange) {
																		field?.onChange(value);
																	}
																	if (
																		field?.name === "provider_id" &&
																		onUtilityTypeChange
																	) {
																		onUtilityTypeChange(value);
																	}
																}}
																optionRender={(option) => (
																	<Space>
																		<span role="img" aria-label={option.data.value}>
																			{option.data.label}
																		</span>
																	</Space>
																)}
															/>
														</FormItem>
													)}
													{field?.component === "checkbox" && (
														<Row justify="space-between">
															<Col span={12}>
																<FormItem name={field?.name} valuePropName="checked">
																	<Checkbox
																		className="rememberMe"
																		onChange={(value) => {
																			setFormValues((prevValues) => ({
																				...prevValues,
																				[field.name]: value,
																			}));
																			if (field?.onChange) {
																				field?.onChange(value);
																			}
																		}}
																	>
																		{field?.label}
																	</Checkbox>
																</FormItem>
															</Col>
															{buttonName === "Sign In" && (
																<Col span={12} className="forgotpassword">
																	<Link
																		to="/forgot-password"
																		style={{ lineHeight: "32px" }}
																		className="forgotLink"
																	>
																		Forgot Password?
																	</Link>
																</Col>
															)}
														</Row>
													)}
												</Col>
											</>

										)
									);
								})}
							</Row>

							<Row gutter={[16, 16]}>

								{hiddenField ? (<></>) : (
									<>
										{renderExpense()}
									</>
								)}


							</Row>

							<Row className="formButtons">
								<Col md={24} className="text-center mt-3">
									{anotherButton && <>{anotherButton}</>}
									<Button
										type="primary"
										htmlType="submit"
										className={anotherButton ? "btnFilled ms-3 w-150" : "authBtn"}
										disabled={loading ? loading : ButtonDisable}
									>
										{loading ? "Loading..." : "Save"}
									</Button>
									{/* {saveAndExit && <>{saveAndExit}</>} */}

								</Col>
							</Row>
						</Form>
					) : (
						<Form
							form={form}
							layout="vertical"
							onFinish={handleFinish}
							onValuesChange={handleValuesChange}
							initialValues={FormInitialValues ? FormInitialValues : formValues}
						>
							<Row gutter={16}>
								{allFields.map((field, index) => {
									const isHidden = field?.hidden
										? typeof field?.hidden === "function"
											? field?.hidden()
											: field?.hidden
										: "";

									const shouldDelete =
										location.pathname === "/property_details" && !field?.static && formType == "add";
									return (
										!isHidden && (
											<Col
												key={field?.name}
												md={field?.colSpan || 24}
												offset={field?.offset || 0}
											>

												{((field?.component === "input" || !field?.component) && field?.type === "phone") &&
													<>
														<FormItem
															label={field?.label}
															name={field?.name}
															rules={[
																{
																	required: true,
																	message: "Please enter phone number.",
																},
																({ getFieldValue }) => ({
																	validator(_, value) {
																		const initialPhoneNumber = getFieldValue(`${field?.name}`);
																		if (initialPhoneNumber === value) {
																			return Promise.resolve();
																		}
																		if (value && value.length === 12) {
																			return Promise.resolve();
																		}

																		return Promise.reject(new Error("Phone number must be exactly 12 digits."));
																	},
																}),
															]}
															dependencies={field?.dependencies ? [field?.dependencies] : undefined}
														>
															<Input
																placeholder={field?.placeholder}
																defaultValue={field?.defaultValue}
																className="form-control"
																prefix={field?.prefix}
																disabled={field?.disabled || ""}
																type="text"
																maxLength={12}
																onKeyPress={(e) => {
																	if (!/[0-9+]/.test(e.key) || (e.key === "+" && e.target.value.includes("+"))) {
																		e.preventDefault();
																	}
																}}
																onPaste={(e) => {
																	const paste = (e.clipboardData || window.clipboardData).getData("text");
																	if (!/^[0-9+]+$/.test(paste) || (paste.indexOf("+") > 0)) {
																		e.preventDefault();
																	}
																}}
															/>
														</FormItem>


														{shouldDelete ? (
															<span className="deleteIcon">
																<HiOutlineTrash
																	style={{ cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
																	onClick={!isDeleteSave ? () => {
																		onDelete(field?.id);
																		// form.resetFields([field?.name]); 
																	} : null}
																/>
															</span>
														) : ''}
													</>

												}

												{((field?.component === "input" || !field?.component) && field?.type !== "phone") &&
													<>
														<FormItem
															label={field?.label}
															name={field?.name}
															rules={field?.rules}
															dependencies={
																field?.dependencies
																	? [field?.dependencies]
																	: undefined
															}
														>
															{field?.type === "password" ? (
																<Input.Password
																	placeholder={field?.placeholder}
																	defaultValue={field?.defaultValue}
																	className="form-control"
																	prefix={field?.prefix}
																	disabled={field?.disabled || ""}
																	type={field?.type}
																/>
															) : (
																<Input
																	placeholder={field?.placeholder}
																	className="form-control"
																	defaultValue={field?.defaultValue}
																	disabled={field?.disabled || ""}
																	prefix={
																		field?.prefix ? (
																			field?.prefix
																		) : (
																			<RiContactsLine
																				style={{
																					color: colors?.primary || "#F47A3A",
																					fontSize: "25px",
																				}}
																			/>
																		)
																	}
																	type={field?.type}
																/>
															)}
														</FormItem>
														{shouldDelete ? (
															<span className="deleteIcon">
																<HiOutlineTrash
																	style={{ cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
																	onClick={!isDeleteSave ? () => {
																		onDelete(field?.id);
																		// form.resetFields([field?.name]); // Remove the field from form validation
																	} : null}
																/>
															</span>
														) : ''}
													</>

												}
												{field?.component === "select" && (
													<FormItem
														label={field?.label}
														name={field?.name}
														rules={field?.rules}
													>
														<Select
															id={index}
															dropdownRender={(originNode) => (
																<div
																	style={{
																		padding: "0px",
																		display:
																			field?.name === "category_id" ||
																				field?.name === "frequency" ||
																				field?.name === "provider_id" ||
																				field?.name === "company_id"
																				? "block"
																				: dropdownVisible
																					? "block"
																					: "none", // Initially hidden
																	}}
																>
																	{originNode}
																</div>
															)}
															className="formControl select-custom "
															disabled={field.disabled || ""}
															showSearch
															onSelect={() => setDropdownVisible(false)}
															onSearch={handleDropdownVisibleChange}
															filterOption={(input, option) =>
																(option?.label ?? "")
																	.toLowerCase()
																	.includes(input.toLowerCase())
															}
															mode={field.mode || ""}
															optionLabelProp="label"
															placeholder={field?.placeholder}
															options={field?.options}
															suffixIcon={field?.suffixIcon}
															onChange={(value) => {
																setFormValues((prevValues) => ({
																	...prevValues,
																	[field?.name]: value,
																}));
																if (field?.onChange) {
																	field?.onChange(value);
																}
																if (
																	field?.name === "provider_id" &&
																	onUtilityTypeChange
																) {
																	onUtilityTypeChange(value);
																}
															}}
															optionRender={(option) => (
																<Space>
																	<span role="img" aria-label={option.data.value}>
																		{option.data.label}
																	</span>
																</Space>
															)}
														/>
													</FormItem>
												)}
												{field?.component === "checkbox" && (
													<Row justify="space-between">
														<Col span={12}>
															<FormItem name={field?.name} valuePropName="checked">
																<Checkbox
																	className="rememberMe"
																	onChange={(value) => {
																		setFormValues((prevValues) => ({
																			...prevValues,
																			[field.name]: value,
																		}));
																		if (field?.onChange) {
																			field?.onChange(value);
																		}
																	}}
																>
																	{field?.label}
																</Checkbox>
															</FormItem>
														</Col>
														{buttonName === "Sign In" && (
															<Col span={12} className="forgotpassword">
																<Link
																	to="/forgot-password"
																	style={{ lineHeight: "32px" }}
																	className="forgotLink"
																>
																	Forgot Password?
																</Link>
															</Col>
														)}
													</Row>
												)}
											</Col>
										)
									);
								})}
							</Row>
							<Row className="formButtons">
								<Col md={24} className="text-center mt-3">
									{anotherButton && <>{anotherButton}</>}
									<Button
										type="primary"
										htmlType="submit"
										className={anotherButton ? "btnFilled ms-3 w-150" : "authBtn"}
										disabled={loading ? loading : ButtonDisable}
									>
										{loading ? "Loading..." : buttonName}
									</Button>
									{saveAndExit && <>{saveAndExit}</>}
								</Col>
							</Row>
						</Form>
					)}
				</>
			)}
		</>
	);
};

export default DynamicForm;
