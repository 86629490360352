import React, { useState, useEffect } from "react";
import { Input, Checkbox, Form, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useUserLoginMutation } from "../../slices/loginRegisterSlice"
import { loginSuccess, userDetail, userStatus } from "../../slices/authSlice";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import images from '../../constant/images';
import {
    MailOutlined,
    LockOutlined
} from '@ant-design/icons';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { Logo, SignInImage } = images;
    const [errorMessage, setErrorMessage] = useState('');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isverified = urlParams.get('isverified');

    if (isverified === 'true') {
        toast.success('You have successfully verified your email. Please login.');
    } if (isverified === 'false') {
        toast.error('Verification failed. Please try again or sign up.');
    }
    useEffect(() => {
        const url = window.location.href;
        const cleanUrl = url.split('?')[0];
        window.history.replaceState(null, '', cleanUrl);
    }, []);

    urlParams.delete('isverified');
    const newUrl = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState({}, document.title, newUrl);

    const [userLogin, { isLoading }] = useUserLoginMutation();

    const navigateBasedOnUserStatus = (user, status, token, parent_id) => {
        if (parent_id) {
            navigate("/dashboard");
        } else {
            if (status === "1" && user === null) {
                dispatch(userStatus(false))
                navigate("/profile");
            } else if (status === "1" && user !== null) {
                navigate("/add_property");
            }
            else if (status === "3" && user != null) {
                navigate("/dashboard");
            }
        }
    };

    const onFinish = async (value) => {
        try {
            const response = await userLogin(value);
            form.resetFields();
            localStorage.setItem('Token', response?.data?.token);
            if (response.error) {
                handleError(response.error.data.message);
                return;
            }

            const { token, user, status, parent_id } = response.data;
            if (token) {
                dispatch(
                    userDetail({
                        user: response?.data,
                    })
                );
                if (status === "1" || status === '3') {

                    dispatch(userStatus(true));
                }
                dispatch(loginSuccess({ user: response.data }));
                navigateBasedOnUserStatus(user, status, token, parent_id);
                toast.success('Login successful');
            }
        } catch (error) {
            handleError('An unexpected error occurred. Please try again.');
        }
    };

    const handleError = (message) => {
        setErrorMessage(message);
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    return (
        <div className="authWrapper">
            <div className="authInner">
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        <div className="col-md-6 whiteBg">
                            <div className="formWrapper">
                                <div className="logoWrap">
                                    <Logo />
                                </div>
                                <div className="titleWrap">
                                    <h2 className="authTitle">Login to your Account</h2>
                                    <p className="authSubTitle">Welcome, please sign-in to shackbox!</p>
                                </div>
                                <div className="buttonWrap">
                                </div>
                                <div className="orTextRow">
                                </div>
                                <div className="formWrap">
                                    {errorMessage && (
                                        <div style={{ color: 'red' }}>
                                            {errorMessage}
                                        </div>
                                    )}
                                    <Form
                                        name="login"
                                        onFinish={onFinish}
                                        initialValues={{
                                            rememberMe: false,
                                        }}
                                        layout="vertical"
                                    >
                                        <Form.Item
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your email!',
                                                },
                                                {
                                                    type: 'email',
                                                    message: 'Please input a valid email!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter Email" className="form-control" prefix={<MailOutlined style={{ color: 'rgb(244, 121, 57)', fontSize: '22px' }} />} />
                                        </Form.Item>

                                        <Form.Item
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your password!',
                                                },
                                            ]}
                                        >
                                            <Input.Password placeholder="Enter Password" className="form-control" prefix={<LockOutlined style={{ color: 'rgb(244, 121, 57)', fontSize: '22px' }} />} />
                                        </Form.Item>

                                        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                            <div>
                                                <Form.Item
                                                    name="rememberMe"
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox className="rememberMe ">Remember Me</Checkbox>
                                                </Form.Item>
                                            </div>
                                            <div className="forgotpassword ">
                                                <a href="/forgot-password" className="forgotLink">Forgot Password?</a>
                                            </div>
                                        </div>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" className="authBtn" loading={isLoading}>
                                                Sign In
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                                <div className="bottomTextRow">
                                    <span>Don’t have account?</span>
                                    <span className="text-wrapper-10">&nbsp;</span>
                                    <Link to='/signup' className="signUpLink">Create an Account</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mobHide">
                            <div className="imgWrapper">
                                <SignInImage />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
