import React, { useEffect, useState } from "react";
import { Form, Spin, Select, Row, Col, Button } from "antd";
import { RiUser2Line } from "react-icons/ri";
import {
  useGetCurrencyQuery,
  useGetUserDetailQuery,
} from "../../slices/getSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useSettingMutation } from "../../slices/settingSlice";
import { saveCurrency } from "../../slices/authSlice";
import { useOutletContext } from "react-router-dom";

const Setting = () => {
  const dispatch = useDispatch()
  const [currency, setCurrency] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const { loading, setLoading } = useOutletContext();
  const [settingId, setSettingid] = useState([]);
  const [formIntinalValue, setFormIntinalValue] = useState("");
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const colors = useSelector((state) => state?.persistedReducer?.colors);

  const fields = [
    {
      component: "select",
      label: "Currency",
      name: "currency",
      placeholder: "Please select the currency",
      suffixIcon: (
        <RiUser2Line style={{ color: colors?.primary || "#F47A3A", fontSize: "25px" }} />
      ),
      options: currency,
      colSpan: 12,
    },
  ];

  const [setting, { isLoading }] = useSettingMutation();
  const { data: getCurrency } = useGetCurrencyQuery();

  const {
    data: getUserDetail,
    refetch,
  } = useGetUserDetailQuery();

  useEffect(() => {
    if (getCurrency && getCurrency.data) {
      const transformedOptions = getCurrency.data.map((item) => ({
        label: `${item.name}${" "}${item.symbol}`,
        value: item.id,
      }));
      setCurrency(transformedOptions);
    }

    if (getUserDetail && getUserDetail.data) {
      const res = getUserDetail.data.setting;
      setSettingid(res?.id);
      const updatedUserProfileData = {
        currency: res?.currency_id,
        logo: `https://shackbox-api.aleaspeaks.com/${res?.logo}`
      };
      dispatch(saveCurrency(getUserDetail?.data?.setting))
      setFormIntinalValue(updatedUserProfileData);
      form.setFieldsValue(updatedUserProfileData);
      setLoading(false)
    }

  }, [getCurrency, getUserDetail]);

  const onFinish = async (values) => {
    setIsSaving(true)
    try {
      let data = new FormData();

      data.append("logo", values?.logo?.originFileObj);
      data.append("currency", values?.currency);


      const response = await setting({ data, id: settingId });
      if (response.data) {
        refetch();
        toast.success("Setting has been updated successfully");
      } else {
        setErrorMessage(response.error.data.message);
        setTimeout(() => {
          setErrorMessage('');
        }, 5000)
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsSaving(false)
    }
  };

  return (
    <>
      {loading && (
        <div className="commanLoaderWrapper">
          <Spin size="large" />
          <h4>Setting things up..</h4>
        </div>
      )}
      <div className="customPadding">
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className='changePasswordFormWrapper'
          initialValues={formIntinalValue}
        >
          <div className='formWrapper'>
            {fields && fields?.map((item, i) => (
              <Form.Item
                label={item?.label}
                name={item?.name}
                rules={item?.rules}
              >
                <Select
                  id={i}
                  className="formControl select-custom "
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  optionLabelProp="label"
                  placeholder={item?.placeholder}
                  options={item?.options}
                  suffixIcon={item?.suffixIcon}
                  optionRender={(option) => (
                    <span role="img" aria-label={option.data.value}>
                      {option.data.label}
                    </span>
                  )}
                />
              </Form.Item>
            ))}
            <Row className="formButtons">
              <Col md={24} className="text-center mt-3">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="authBtn"
                  disabled={isLoading ? isLoading : isSaving}
                >
                  {isLoading ? "Loading..." : 'Save'}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Setting;
