import React, { useState, useCallback } from "react";
import { Spin, Button, Table } from "antd";
import { useNavigate } from "react-router-dom";
import Title from "../../component/PageTitle/index";
import { useGenrateLinkTokenQuery } from "../../slices/getSlice";
import { useSelector } from "react-redux";
import { useAddAccessTokenMutation } from '../../slices/apiSlice';
import { toast } from 'react-toastify';
import { usePlaidLink } from 'react-plaid-link';
import { useOutletContext } from "react-router-dom";

const PlaidLink = () => {
    const { data: generateToken, refetch, isLoading, error } = useGenrateLinkTokenQuery();

    if (isLoading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <Spin size="large" />
            </div>
        );
    }

    if (error) {
        return <div>Error generating link token</div>;
    }

    const linkToken = generateToken?.token?.link_token;

    return linkToken ? (
        <ConnectBankAccount linkToken={linkToken} generatedToken={generateToken} refetch={refetch} isLoading={isLoading} />
    ) : (
        <div>No link token available</div>
    );
};

const ConnectBankAccount = ({ linkToken, generatedToken, refetch, isLoading }) => {
    const navigate = useNavigate();
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const currency = useSelector((state) => state?.persistedReducer?.currency?.currency_symbol || "$");
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5;
    const [setAccessToken] = useAddAccessTokenMutation();
    const [loadingData, setLoadingData] = useState(false);
    const { loading, setLoading } = useOutletContext();

    setLoading(false)

    const onSuccess = useCallback(
        async (public_token) => {
            try {
                setLoadingData(true);
                const response = await setAccessToken({ public_token });

                if (response.error) {
                    toast.error(response.error.data.message);
                } else {
                    toast.success('Account Connected');
                    const aa = await refetch();
                }
            } catch (error) {
                toast.error('Error connecting account');
            } finally {
                setLoadingData(false);
            }
        },
        [setAccessToken, refetch, navigate]
    );

    const config = {
        token: linkToken,
        onSuccess,
    };

    const { open, ready } = usePlaidLink(config);

    const columns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            render: (_, __, index) => (currentPage - 1) * pageSize + index + 1,
        },
        {
            title: "Bank Name",
            dataIndex: "bank_name",
        },
        {
            title: "Account Number",
            dataIndex: "account_id",
        },
        {
            title: "Account Name",
            dataIndex: "account_name",
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            render: (text) => {
                const date = new Date(text);
                const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
                return date.toLocaleDateString('en-US', options);
            },
        },
        {
            title: "Updated At",
            dataIndex: "updated_at",
            render: (text) => {
                const date = new Date(text);
                const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
                return date.toLocaleDateString('en-US', options);
            },
        },
    ];

    return (
        <div className="customPadding">
            <div className="mainWrapper">
                <div className="card titleCard mb-4">
                    <div className="card-body">
                        <Title title="Connect Bank Account" id="connect_bank_account" />
                        <Button
                            type="primary"
                            className="link"
                            style={{
                                backgroundColor: colors?.primary || "#F47A3A",
                                color: "#FFF",
                            }}
                            onClick={() => open()}
                            disabled={!ready}
                        >
                            {generatedToken?.accounts_data?.length ? "Link More Account" : "Link Account"}
                        </Button>
                    </div>
                </div>
                {loadingData || isLoading ? (
                    <div
                        style={{
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Spin size="large" />
                    </div>
                ) : (
                    <Table
                        rowKey={(record) => record.id}
                        rowClassName={() => "editable-row"}
                        bordered
                        dataSource={generatedToken?.accounts_data}
                        columns={columns}
                        scroll={{ x: '100%' }}
                        pagination={{
                            pageSize,
                            current: currentPage,
                            onChange: (page) => setCurrentPage(page),
                        }}
                    />
                )}

            </div>
        </div>
    );
};

export default PlaidLink;
