import React from 'react'
import { Form, Row, Col, Input, Button } from "antd";

const ChangePasswordForm = ({
    form,
    fields,
    onFinish,
    buttonName,
    ButtonDisable,
    loading
}) => {

    const onHandleFinish = (values) => {
        onFinish(values);
    }

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={onHandleFinish}
                className='changePasswordFormWrapper'
            >
                <div className='formWrapper'>
                    <Row>
                        {fields && fields?.map((item, i) => (
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={item?.label}
                                    name={item?.name}
                                    rules={item?.rules}
                                    dependencies={
                                        item?.dependencies
                                            ? [item?.dependencies]
                                            : undefined
                                    }
                                >
                                    <Input.Password
                                        placeholder={item?.placeholder}
                                        defaultValue={item?.defaultValue}
                                        className="form-control"
                                        prefix={item?.prefix}
                                        disabled={item?.disabled || ""}
                                        type={item?.type}
                                    />
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>
                </div>
                <div className='buttonWrapper'>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="authBtn"
                            disabled={loading ? loading : ButtonDisable}
                        >
                            {loading ? "Loading..." : buttonName}
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </>
    )
}

export default ChangePasswordForm;