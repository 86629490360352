import React, { useEffect, useState } from "react";
import Title from "../../component/PageTitle";
import {
    Button,
    Form,
    Collapse,
    Pagination,
    Tooltip,
    Modal,
    Spin,
    Empty
} from "antd";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoHomeOutline } from "react-icons/io5";
import { LiaUserEditSolid } from "react-icons/lia";
import CommonModal from "../../component/CommonModal";
import { toast } from "react-toastify";
import {
    useAddGroupMutation,
    useDeleteGroupMutation,
    useEditGroupMutation,
} from "../../slices/apiSlice";
import {
    useGetPropertiesQuery,
    useGetGroupQuery,
    useGetUtilityCategoryQuery,
    useGetLabelsQuery
} from "../../slices/getSlice";
import { useNavigate, useOutletContext } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { HiOutlineTrash } from "react-icons/hi2";
import { RxCrossCircled } from "react-icons/rx";
import { useSelector } from "react-redux";

const ManageProperty = () => {
    const [form] = Form.useForm();
    const { loading, setLoading } = useOutletContext();
    const [properties, setProperties] = useState([]);
    const [propertyid, setPropertyId] = useState();
    const [modelOpen, setModelOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const [
        addGroup,
        { isLoading: isLoadingAddGroup }
    ] = useAddGroupMutation();

    const {
        data: getProperties,
        refetch: refetchProperties,
    } = useGetPropertiesQuery();

    const {
        data: getGroup,
        isLoading: isGroupLoading,
        isError: isGroupError,
        refetch: refetchGroup,
        error
    } = useGetGroupQuery();

    const {
        data: labelData,
        error: labelsError,
    } = useGetLabelsQuery('group_form');

    const {
        data: getUtilityCategory,
    } = useGetUtilityCategoryQuery();

    const [currentPage, setCurrentPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState([])
    const [selectedRecord, setSelectedRecord] = useState("");
    const [FormInitialValues, setFormInitialValues] = useState({
        id: "",
        name: "",
        properties: "",
    });
    const [propertyName, setPropertyName] = useState();
    const [propertyData, setPropertyData] = useState([])
    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const ITEMS_PER_PAGE = 5;
    const startIdx = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIdx = startIdx + ITEMS_PER_PAGE;
    const [deletegroup,] = useDeleteGroupMutation();
    const [editgroup, { isLoading: isLoadingEditGroup }] = useEditGroupMutation();

    const navigate = useNavigate();
    useEffect(() => {
        if (getProperties && getProperties?.data) {
            const transformedOptions = getProperties?.data?.map((item) => ({
                label: item.name_of_property,
                value: item.id,
            }));
            setLoading(false)
            setProperties(transformedOptions);
        }
    }, [getProperties]);

    const handleAdd = async () => {
        form.resetFields();
        setFormInitialValues({
            name: "",
            properties: []
        });
        setModelOpen(true);
    };

    const handleCancel = () => {
        setIsSaving(false)
        setModelOpen(false);
        setIsModalOpen(false);
    };

    const handleOk = async () => {
        const res = await deletegroup(selectedRecord);
        if (res?.data?.message) {
            refetchGroup();
            setIsModalOpen(false);
            toast.success(res?.data?.message)
        }
    };

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };

    const fields = [
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter(labelData?.enter_group_name) || "Enter Group Name",
            name: "name",
            placeholder: "Enter Group Name",

            prefix: (
                <LiaUserEditSolid style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: "Group name is required",
                },
                {
                    pattern: /^(?!\s)[A-Za-z\s]+(?<!\s)$/,
                    message: 'Group name must only contain letters',
                },
            ],
        },
        {
            component: "select",
            type: "text",
            mode: "multiple",
            label: capitalizeFirstLetter(labelData?.select_property) || "Select Property",
            name: "properties",
            onChange: (value) => {
                setSelectedValues(value);
            },
            placeholder: "Please select property",
            suffixIcon: (
                <IoHomeOutline
                    style={{
                        color: "#F47939",
                        fontSize: "25px",
                    }}
                />
            ),
            rules: [
                {
                    required: true,
                    message: "Please select property",
                },
            ],
            options: properties,
        },
    ];

    const onFinish = async (data, formType) => {
        const id = FormInitialValues.id;
        if (formType == "edit") {
            let filterdProperties = [];

            if (data?.properties?.length) {
                data?.properties?.map((item) => {
                    if (typeof item === 'number') {
                        filterdProperties.push(item)
                    } else {
                        const findProperty = properties?.find((record) => {
                            return item == record.label;
                        });
                        if (findProperty !== undefined) {
                            filterdProperties.push(findProperty.value);
                        }
                    }

                });
            }
            data.properties = filterdProperties;
        }

        if (id) {
            try {
                setIsSaving(true)
                const res = await editgroup({ data: data, id });
                if (res.data) {
                    toast.success("Group has been Updated successfully");
                    refetchGroup();
                    form.resetFields();
                    setModelOpen(false);
                } else {
                    toast.error(`${res?.error?.data?.message}`);
                    setIsSaving(false)
                }
            } catch {
            } finally {
                setIsSaving(false)
            }
        } else {
            try {
                setIsSaving(true)
                const response = await addGroup(data);
                if (response.data) {
                    refetchGroup();
                    form.resetFields();
                    setModelOpen(false)
                    toast.success("Group has been created successfully");
                } else {
                    toast.error(`${response?.error?.data?.message}`);
                }
            } catch (error) {
            } finally {
                setIsSaving(false)
            }
        }
    };

    const handleClick = (detail) => {
        navigate("/property_details", {
            state: {
                data: detail,
                propertyid: propertyid,
                propertyName: propertyName,
                propertyData: propertyData
            },
        });
    };

    const items = getGroup?.data?.map((item) => {
        const propertyItems = item.properties.map((property) => ({
            key: property.id,
            onClick: () => {
                setPropertyId(property.id);
                setPropertyName(property.name_of_property);
                setPropertyData(property)
            },
            label: (
                <>
                    {isGroupLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Spin />
                        </div>
                    ) : isGroupError ? (
                        <h4>An error occurred</h4>
                    ) : (
                        <div>
                            {property.name_of_property} - {property.zip_code}
                        </div>
                    )}
                </>
            ),
            children: (
                <>
                    {getUtilityCategory?.data?.map((utility) => (
                        <div className="provider-content" key={utility.id}>
                            <div>{utility.name}</div>
                            <Button className="add-new" onClick={() => handleClick(utility)}>
                                {" "}
                                {"Add New"}
                            </Button>
                        </div>
                    ))}
                </>
            ),
        }));

        return {
            key: item.group_id,
            label: (
                <div className="d-flex justify-content-between align-items-center">
                    {item.group_name}
                    <div className="action-container">
                        <Tooltip title="Edit">
                            <button
                                className="btn btnEdit me-2 border-0"
                                onClick={() => {
                                    setModelOpen(true);
                                    setFormInitialValues({
                                        id: item.group_id,
                                        name: item.group_name,
                                        properties: item.properties.map((data) => {
                                            return data?.name_of_property;
                                        }),
                                    });
                                }}
                            >
                                <FaEdit />
                            </button>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <button
                                className="btn btnDelete me-2 border-0"
                                onClick={() => {
                                    setSelectedRecord(item.group_id);
                                    setIsModalOpen(true);
                                }}
                            >
                                <HiOutlineTrash />
                            </button>
                        </Tooltip>
                    </div>
                </div>
            ),
            children: (
                <div>
                    <Collapse accordion items={propertyItems} />
                </div>
            ),
        };
    });

    const currentItems = items ? items.slice(startIdx, endIdx) : [];

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
      <div className="customPadding">
            <div className="mainWrapper">
                <div className="card titleCard mb-4">
                    <div className="card-body">
                        <Title title="Manage Groups" id="groups" />
                        <Button
                            onClick={handleAdd}
                            type="primary"
                            style={{
                                backgroundColor: colors?.primary || "#F47A3A",
                            }}
                        >
                            Add Group <IoIosAddCircleOutline style={{ marginLeft: "10px" }} />
                        </Button>
                    </div>
                </div>

                <div className="card tableCard">
                    <div className="card-body p-4">
                        {isGroupLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <Spin />
                            </div>
                        ) : isGroupError && currentItems.length < 0 ? (
                            <h4>An error occurred</h4>
                        ) : (
                            <>
                                {currentItems.length > 0 ? (
                                    <div className="main-collapse">
                                        <div className="collapse-box">
                                            <Collapse
                                                className="groupCollapse"
                                                items={
                                                    currentItems.length > 0 ? (
                                                        currentItems
                                                    ) : (
                                                        <p>No data found</p>
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data found" />
                                )}
                                {items && items.length > ITEMS_PER_PAGE && (
                                    <Pagination
                                        className="mt-4"
                                        current={currentPage}
                                        pageSize={ITEMS_PER_PAGE}
                                        total={items.length}
                                        onChange={handlePageChange}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
                <Modal
                    className="deleteModal"
                    title="Delete Group"
                    open={isModalOpen}
                    onCancel={handleCancel}
                    footer={false}
                >
                    <div className="iconRow">
                        <RxCrossCircled />
                    </div>
                    <div className="textRow">
                        <h3>Are you sure?</h3>
                        <p>Do you really want to delete this group?</p>
                    </div>
                    <div className="buttonRow">
                        <Button className="btnOutlined me-2" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button className="btnFilled text-white" onClick={() => handleOk()}>
                            Delete
                        </Button>
                    </div>
                </Modal>
                <CommonModal
                    form={form}
                    open={modelOpen}
                    close={handleCancel}
                    onFinish={onFinish}
                    fields={fields}
                    selectedValues={selectedValues}
                    title={FormInitialValues.id ? "Edit Group" : "Add Group"}
                    FormInitialValues={FormInitialValues}
                    formType={FormInitialValues.id ? "edit" : 'add'}
                    ButtonDisable={isSaving}
                    loading={FormInitialValues.id ? isLoadingEditGroup : isLoadingAddGroup}
                />
            </div>
            </div>
        </>
    );
};
export default ManageProperty;
