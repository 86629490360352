import React, { useState } from "react";
import {
    Button,
    Form,
    Input,
    Select,
    Row,
    Col,
    Space,
} from "antd";
import { RiContactsLine } from "react-icons/ri";
import { useSelector } from "react-redux";
const { Item: FormItem } = Form;

const UpdateProfileForm = ({
    form,
    fields,
    onFinish,
    buttonName,
    ButtonDisable,
    loading,
    FormInitialValues = {},
}) => {
    const [formValues, setFormValues] = useState({});
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const colors = useSelector((state) => state?.persistedReducer?.colors);

    const handleDropdownVisibleChange = (value) => {
        if (value) {
            setDropdownVisible(true);
        } else {
            setDropdownVisible(false);
        }
    };

    const handleValuesChange = (allValues) => {
        if (allValues) {
            setFormValues(allValues);
        }
    };

    const handleFinish = (allValues) => {
        onFinish(allValues);
    };

    const handleFieldChange = (field, value, setFormValues) => {
        const updatedFormValues = {
            ...formValues,
            [field?.name]: value,
        };

        setFormValues(updatedFormValues);

        if (field?.onChange) {
            field.onChange(value);
        }
    };

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFinish}
                onValuesChange={handleValuesChange}
                initialValues={FormInitialValues ? FormInitialValues : formValues}
            >
                <Row gutter={16}>
                    {fields.map((field, index) => {
                        return (
                            <>
                                <Col
                                    key={field?.name}
                                    md={field?.colSpan || 24}
                                    offset={field?.offset || 0}
                                >
                                    {((field?.component === "input" || !field?.component) && field?.type !== "phone") &&
                                        <>
                                            <FormItem
                                                label={field?.label}
                                                name={field?.name}
                                                rules={field?.rules}
                                                dependencies={
                                                    field?.dependencies
                                                        ? [field?.dependencies]
                                                        : undefined
                                                }
                                            >
                                                <Input
                                                    placeholder={field?.placeholder}
                                                    className="form-control"
                                                    defaultValue={field?.defaultValue}
                                                    disabled={field?.disabled || ""}
                                                    prefix={
                                                        field?.prefix ? (
                                                            field?.prefix
                                                        ) : (
                                                            <RiContactsLine
                                                                style={{
                                                                    color: colors?.primary || "#F47A3A",
                                                                    fontSize: "25px",
                                                                }}
                                                            />
                                                        )
                                                    }
                                                    type={field?.type}
                                                />
                                            </FormItem>
                                        </>
                                    }

                                    {field?.component === "select" && (
                                        <FormItem
                                            label={field?.label}
                                            name={field?.name}
                                            rules={field?.rules}
                                        >
                                            <Select
                                                id={index}
                                                dropdownRender={(originNode) => (
                                                    <div
                                                        style={{
                                                            padding: "0px",
                                                            display:
                                                                field?.name === "category_id" ||
                                                                    field?.name === "frequency" ||
                                                                    field?.name === "provider_id" ||
                                                                    field?.name === "company_id"
                                                                    ? "block"
                                                                    : dropdownVisible
                                                                        ? "block"
                                                                        : "none",
                                                        }}
                                                    >
                                                        {originNode}
                                                    </div>
                                                )}
                                                className="formControl select-custom "
                                                disabled={field.disabled || ""}
                                                showSearch
                                                onSelect={() => setDropdownVisible(false)}
                                                onSearch={handleDropdownVisibleChange}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? "")
                                                        .toLowerCase()
                                                        .includes(input.toLowerCase())
                                                }
                                                mode={field.mode || ""}
                                                optionLabelProp="label"
                                                placeholder={field?.placeholder}
                                                options={field?.options}
                                                suffixIcon={field?.suffixIcon}
                                                onChange={(value) => handleFieldChange(field, value, setFormValues)}
                                                optionRender={(option) => (
                                                    <Space>
                                                        <span role="img" aria-label={option.data.value ? option.data.value : option.value}>
                                                            {option.data.label ? option.data.label : option.label}
                                                        </span>
                                                    </Space>
                                                )}
                                            />
                                        </FormItem>
                                    )}
                                </Col>
                            </>
                        );
                    })}
                </Row>
                <Row className="formButtons">
                    <Col md={24} className="text-center mt-3">
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="authBtn"
                            disabled={loading ? loading : ButtonDisable}
                        >
                            {loading ? "Loading..." : buttonName ? buttonName : "Save"}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default UpdateProfileForm;
