import React, { useState } from "react";
import {
	Button,
	Form,
	Input,
	Select,
	Row,
	Col,
	Space,
} from "antd";
import { RiContactsLine } from "react-icons/ri";
import { useSelector } from "react-redux";

const AddPropertyForm = ({
	form,
	fields,
	onFinish,
	buttonName,
	loading,
	ButtonDisable,
	FormInitialValues = {},
}) => {
	const [formValues, setFormValues] = useState({});
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const colors = useSelector((state) => state?.persistedReducer?.colors);

	const handleDropdownVisibleChange = (value) => {
		if (value) {
			setDropdownVisible(true);
		} else {
			setDropdownVisible(false);
		}
	};
	const handleValuesChange = (allValues) => {
		if (allValues) {

			setFormValues((prevValues) => ({
				...prevValues,
				...allValues,
			}));
		}
	};

	const handleFinish = (allValues) => {
		onFinish(allValues);
	};

	const allFields = fields;

	const handleFieldChange = (field, value, setFormValues) => {
		const updatedFormValues = {
			...formValues,
			[field?.name]: value,
		};

		setFormValues(updatedFormValues);

		if (field?.onChange) {
			field.onChange(value);
		}
	};

	return (
		<>
			<Form
				form={form}
				layout="vertical"
				autoComplete="off"
				onFinish={handleFinish}
				onValuesChange={handleValuesChange}
				initialValues={FormInitialValues ? FormInitialValues : formValues}
			>
				<input type="text" style={{ display: 'none' }} autoComplete="off" />
				<Row gutter={16}>
					{allFields.map((field, index) => {
						const isHidden = field?.hidden
							? typeof field?.hidden === "function"
								? field?.hidden()
								: field?.hidden
							: "";

						return (
							!isHidden && (
								<>
									<Col
										key={field?.name}
										md={field?.colSpan || 24}
										offset={field?.offset || 0}
									>
										{((field?.component === "input" || !field?.component) && field?.type === "phone") &&
											<>
												<Form.Item
													label={field?.label}
													name={field?.name}
													rules={[
														{
															required: true,
															message: "Please enter phone number.",
														},
														({ getFieldValue }) => ({
															validator(_, value) {
																const initialPhoneNumber = getFieldValue(`${field?.name}`);
																if (initialPhoneNumber === value) {
																	return Promise.resolve();
																}
																if (value && value.length === 12) {
																	return Promise.resolve();
																}

																return Promise.reject(new Error("Phone number must be exactly 12 digits."));
															},
														}),
													]}
													dependencies={field?.dependencies ? [field?.dependencies] : undefined}
												>
													{console.info("RENDERING", field.label)}
													<Input
														placeholder={field?.placeholder}
														defaultValue={field?.defaultValue}
														className="form-control"
														prefix={field?.prefix}
														disabled={field?.disabled || ""}
														type="text"
														autoComplete="new-field"
														maxLength={12}
														onKeyPress={(e) => {
															if (!/[0-9+]/.test(e.key) || (e.key === "+" && e.target.value.includes("+"))) {
																e.preventDefault();
															}
														}}
														onPaste={(e) => {
															const paste = (e.clipboardData || window.clipboardData).getData("text");
															if (!/^[0-9+]+$/.test(paste) || (paste.indexOf("+") > 0)) {
																e.preventDefault();
															}
														}}
													/>
												</Form.Item>
											</>
										}

										{((field?.component === "input" || !field?.component) && field?.type !== "phone") &&
											<>
												<Form.Item
													label={field?.label}
													name={field?.name}
													rules={field?.rules}
													dependencies={
														field?.dependencies
															? [field?.dependencies]
															: undefined
													}
												>
													{console.info("formValues", formValues)}
													<Input
														placeholder={field?.placeholder}
														className="form-control"
														defaultValue={field?.defaultValue}
														autoComplete="new-field"
														disabled={
															field?.label === "Zip code" &&
															(!formValues?.country_id || !formValues?.state_id || !formValues?.city_id)
														}
														prefix={
															field?.prefix ? (
																field?.prefix
															) : (
																<RiContactsLine
																	style={{
																		color: colors?.primary || "#F47A3A",
																		fontSize: "25px",
																	}}
																/>
															)
														}
														type={field?.type}
													/>
												</Form.Item>
											</>
										}

										{field?.component === "select" && (
											<Form.Item
												label={field?.label}
												name={field?.name}
												rules={field?.rules}
											>
												{console.info("field?.name", field?.name)}
												<Select
													id={index}
													dropdownRender={(originNode) => (
														<div
															style={{
																padding: "0px",
																display: dropdownVisible ? "block" : "none",
															}}
														>
															{originNode}
														</div>
													)}
													className="formControl select-custom "
													disabled={
														(field?.label === "State" && !formValues?.country_id) ||
														(field?.label === "City" && (!formValues?.country_id || !formValues?.state_id))
													}
													showSearch
													autoComplete="new-field"
													onSelect={() => setDropdownVisible(false)}
													onSearch={handleDropdownVisibleChange}
													filterOption={(input, option) =>
														(option?.label ?? "")
															.toLowerCase()
															.includes(input.toLowerCase())
													}
													mode={field.mode || ""}
													optionLabelProp="label"
													placeholder={field?.placeholder}
													options={field?.options}
													suffixIcon={field?.suffixIcon}
													onChange={(value) => handleFieldChange(field, value, setFormValues)}
													optionRender={(option) => (
														<Space>
															<span role="img" aria-label={option.data.value ? option.data.value : option.value}>
																{option.data.label ? option.data.label : option.label}
															</span>
														</Space>
													)}
												/>
											</Form.Item>
										)}
									</Col>
								</>
							)
						);
					})}
				</Row>
				<Row className="formButtons">
					<Col md={24} className="text-center mt-3">
						<Button
							type="primary"
							htmlType="submit"
							className="authBtn"
							disabled={loading ? loading : ButtonDisable}
						>
							{loading ? "Loading..." : buttonName ? buttonName : "Save"}
						</Button>
					</Col>
				</Row>
			</Form>
		</>
	);
};

export default AddPropertyForm;
