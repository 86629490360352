import React, { useEffect, useState } from "react";
import {
	Button,
	Form,
	Input,
	Select,
	Row,
	Col,
	Checkbox,
	Space,
	message,
	Spin,
	DatePicker
} from "antd";
import { RiContactsLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { HiOutlineTrash } from "react-icons/hi";
import { IoMdAddCircle } from "react-icons/io";
import { FaMinusCircle } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import dayjs from 'dayjs';
const { Item: FormItem } = Form;

const PropertyDetailsForm = ({
	form,
	fields,
	onFinish,
	buttonName,
	onUtilityTypeChange,
	anotherButton,
	saveAndExit,
	loading,
	dynamicfields,
	formType,
	ButtonDisable,
	handleDelete,
	isDeleteSave,
	FormInitialValues = {},
	propertyProvider,
	compnayID,
	companyAdd
}) => {
	const [formValues, setFormValues] = useState({});
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const location = useLocation()
	const colors = useSelector((state) => state?.persistedReducer?.colors);
	const [loader, setLoader] = useState(false)
	const [expenseData, setExpenseData] = useState([
		{ id: Date.now(), category_id: null, amount: "", start_date: "" },
	]);
	const [inputValue, setInputValue] = useState("");
	const [showDropdown, setShowDropdown] = useState(true);

	let hiddenField = true;

	const handleDropdownVisibleChange = (value) => {
		if (value) {
			setDropdownVisible(true);
		} else {
			setDropdownVisible(false);
		}
	};

	const handleValuesChange = (changedValues, allValues) => {
		if (changedValues?.provider_id) {
			dynamicfields = null;
			if (onUtilityTypeChange) {
				onUtilityTypeChange(changedValues?.provider_id);
			}
		}

		if (allValues) {
			setFormValues(allValues);
		}
	};

	useEffect(() => {
		if (FormInitialValues?.company_id == null) {
			form.resetFields(["company_id"]);
		}
		if (FormInitialValues?.state_id == null) {
			form.resetFields(["state_id"]);
			form.resetFields(["city_id"]);
		}
	}, [FormInitialValues]);

	const mainField = fields?.filter((item) => item.name === "provider_id" || item.name === "company_id");
	const otherFields = fields?.filter((item) => item.name !== "provider_id" && item.name !== "company_id");
	hiddenField = propertyProvider ? false : otherFields[0]?.hidden;

	useEffect(() => {
		setLoader(true);

		if (propertyProvider?.expenses) {
			const formattedExpenses = propertyProvider.expenses.map((expense) => ({
				id: expense.id,
				category_id: expense.category_id,
				amount: expense.amount,
				start_date: expense.start_date,
			}));
			setExpenseData(formattedExpenses);
		}

		if (propertyProvider?.company || compnayID) {
			let IdValue = compnayID ? compnayID : propertyProvider !== null && propertyProvider !== undefined && propertyProvider?.company !== undefined && propertyProvider?.company !== null ? propertyProvider?.company.id : '';

			form.setFieldsValue({
				company_id: IdValue,
			});
		}

		if (propertyProvider?.provider?.id) {
			form.setFieldsValue({
				provider_id: propertyProvider.provider.id,
			});
		}

		setTimeout(() => {
			if (propertyProvider?.form_fields) {
				Object.entries(propertyProvider.form_fields).forEach(([InputID, value]) => {
					if (value) {
						form.setFieldsValue({ [InputID]: value });
					}
				});
			}
		}, 2000);

		setTimeout(() => {
			setLoader(false);
		}, 2700);
	}, [propertyProvider, form, compnayID]);

	const handleFinish = (allValues) => {
		const staticValues = {};
		const dynamicValues = {};
		const errors = {};

		expenseData.forEach((row) => {
			const rowErrors = {};
			if (!row.category_id) rowErrors.category_id = "Expense Type is required.";
			if (!row.amount) rowErrors.amount = "Amount is required.";
			if (!row.start_date) rowErrors.start_date = "Start Date is required.";

			if (Object.keys(rowErrors).length > 0) {
				errors[row.id] = rowErrors;
			}
		});

		if (Object.keys(errors).length > 0 && (formType.toLowerCase() === "add" || formType.toLowerCase() === "save")) {
			message.warning('Please fill in all required fields before submitting.');
			return;
		}

		if (dynamicfields && !propertyProvider) {
			for (const field of allFields) {
				const value = formValues[field?.name];
				if (field?.static) {
					staticValues[field?.name] = value;
				} else {
					dynamicValues[field?.name] = value !== undefined ? value : field?.defaultValue;
				}
			}

			if (mainField?.length > 0 && otherFields?.length > 0) {
				staticValues["expenses"] = expenseData;
			}

			onFinish(staticValues, dynamicValues, formType);
		} else {
			for (const field of allFields) {
				const value = formValues[field?.name];

				if (field?.static) {
					staticValues[field?.name] = value;

					if (field?.name === "provider_id") {
						staticValues[field?.name] = allValues?.provider_id;
					}

					if (field?.name === "company_id") {
						staticValues[field?.name] = allValues?.company_id;
					}
				} else {
					if (field?.name !== "provider_id" && field?.name !== "company_id") {
						dynamicValues[field?.name] = value !== undefined ? value : field?.defaultValue;
					}
				}

				if (formType === "add") {
					staticValues["provider_type_id"] =
						propertyProvider?.provider_type_id ?? location.state.propertyData?.property_typeId;
					staticValues["property_id"] =
						propertyProvider?.property_id ?? location.state.propertyid;
				}
			}

			for (const key in allValues) {
				if (key !== "provider_id" && key !== "company_id") {
					dynamicValues[key] = allValues[key];
				}
			}

			if (mainField?.length > 0 && otherFields?.length > 0) {
				staticValues["expenses"] = expenseData;
			}

			onFinish(staticValues, dynamicValues, formType);
		}
	};

	const allFields = dynamicfields ? [...mainField, ...dynamicfields, ...otherFields] : [...mainField, ...otherFields];

	const onDelete = (fieldId) => {
		handleDelete(fieldId);
	};

	const renderExpense = () => {
		const addRow = () => {
			setExpenseData([
				...expenseData,
				{ id: Date.now(), category_id: null, amount: "", start_date: "", type: "new" },
			]);
		};

		const removeRow = (id) => {
			setExpenseData(expenseData.filter((row) => row.id !== id));
		};

		const handleSelectChange = (value, id) => {
			setExpenseData(
				expenseData.map((row) =>
					row.id === id ? { ...row, category_id: value } : row
				)
			);
		};

		const handleInputChange = (e, id) => {
			setExpenseData(
				expenseData.map((row) =>
					row.id === id ? { ...row, amount: e.target.value } : row
				)
			);
		};

		const handleDateChange = (dateString, id) => {
			const formattedDate = dateString ? dayjs(dateString, "MM/DD/YYYY").format("YYYY-MM-DD") : null;

			setExpenseData(
				expenseData.map((row) =>
					row.id === id ? { ...row, start_date: formattedDate } : row
				)
			);
		};

		return (
			<>
				{expenseData.map((row) => {
					const dateValue = row.start_date ? dayjs(row.start_date, "YYYY-MM-DD") : null;

					const disabledDate = (current) => {
						return current && current.year() > dayjs().year();
					};

					const expenseOptions = otherFields?.find((item) => item?.name === "category_id").options;

					return (
						<Col key={row.id} span={24}>
							<div className="row" align="middle">
								<div className="col-md-4 mb-3">
									<Select
										value={row.category_id}
										onChange={(value) => handleSelectChange(value, row.id)}
										placeholder="Select Expense Type"
										className="formControl select-custom "
										options={expenseOptions}
										style={{ width: "100%" }}
									/>
								</div>

								<div className="col-md-4 mb-3">
									<Input
										type="number"
										value={row.amount}
										onChange={(e) => handleInputChange(e, row.id)}
										placeholder="Enter Amount"
										className="form-control"
										onKeyPress={(e) => {
											if (e.key === "-" || e.key === "e") {
												e.preventDefault();
											}
										}}
										min="0"
									/>
								</div>

								<div className="col-md-3 col-10 mb-3">
									<DatePicker
										key={row.id}
										value={dateValue}
										onChange={(date, dateString) => handleDateChange(dateString, row.id)}
										format="MM/DD/YYYY"
										className="form-control"
										placeholder="Select a date"
										disabledDate={disabledDate}
									/>
								</div>

								<div className="col-md-1 col-2">
									{propertyProvider && row?.type !== "new" ? (
										<>
											<div className="edit-del-exp-wraper">
												<>
													<AiFillDelete onClick={() => removeRow(row?.id)} style={{ fontSize: "20px", color: "#ff0000", cursor: "pointer" }} />
												</>
											</div>
										</>
									) : (
										<Button
											type="danger"
											icon={<FaMinusCircle style={{ fontSize: "20px", color: "#ff0000", cursor: "pointer" }} />}
											onClick={() => removeRow(row.id)}
										>
										</Button>
									)}
								</div>
							</div>
						</Col>
					)
				})}
				<Button
					type="dashed"
					style={{ width: "100%", marginTop: "16px", backgroundColor: "#f47a3a", color: "#fff" }}
					icon={<IoMdAddCircle />}
					onClick={addRow}
				>
					Add More
				</Button>
			</>
		);
	};

	const checkExistingProviderID = (data, fields, updatedFormValues, setFormValues) => {
		const newFormValues = { ...updatedFormValues };

		if (fields.name === 'provider_id' && fields.provider_id !== data) {
			newFormValues.company_id = null;
		}

		setFormValues(newFormValues);
	};

	const handleFieldChange = (field, value, onUtilityTypeChange, setFormValues) => {
		const updatedFormValues = {
			...formValues,
			[field?.name]: value,
		};

		setFormValues(updatedFormValues);

		if (field?.onChange) {
			field.onChange(value);
		}

		if (field?.name === "provider_id" && onUtilityTypeChange) {
			onUtilityTypeChange(value);
		}

		checkExistingProviderID(value, field, updatedFormValues, setFormValues);
	};

	const handleAddUtilityCompany = (value) => {
		const providerIDValue = propertyProvider?.provider_id || '';
		const providerTypeID = propertyProvider?.provider_type_id || '';

		const formData = {
			providerID: providerIDValue,
			provider_type_id: providerTypeID,
			company_name: value,
		};

		return companyAdd(formData);
	};

	return (
		<>
			{loader ? (
				<div className="d-flex justify-content-center align-items-center">
					<Spin />
				</div>
			) : (
				<>
					{mainField?.length > 0 && otherFields?.length > 0 ? (
						<Form
							form={form}
							layout="vertical"
							onFinish={handleFinish}
							onValuesChange={handleValuesChange}
							initialValues={FormInitialValues ? FormInitialValues : formValues}
						>
							<Row gutter={16}>
								{mainField.map((field, index) => {
									const isHidden = propertyProvider ? false : field?.hidden
										? typeof field?.hidden === "function"
											? field?.hidden()
											: field?.hidden
										: "";

									const shouldDelete =
										location.pathname === "/property_details" && !field?.static && formType == "add";

									return (
										!isHidden && (
											<>
												<Col
													key={field?.name}
													md={field?.colSpan || 24}
													offset={field?.offset || 0}
												>
													{((field?.component === "input" || !field?.component) && field?.type === "phone") &&
														<>
															<FormItem
																label={field?.label}
																name={field?.name}
																rules={[
																	{
																		required: true,
																		message: "Please enter phone number.",
																	},
																	({ getFieldValue }) => ({
																		validator(_, value) {
																			const initialPhoneNumber = getFieldValue(`${field?.name}`);
																			if (initialPhoneNumber === value) {
																				return Promise.resolve();
																			}
																			if (value && value.length === 12) {
																				return Promise.resolve();
																			}

																			return Promise.reject(new Error("Phone number must be exactly 12 digits."));
																		},
																	}),
																]}
																dependencies={field?.dependencies ? [field?.dependencies] : undefined}
															>
																<Input
																	placeholder={field?.placeholder}
																	defaultValue={field?.defaultValue}
																	className="form-control"
																	prefix={field?.prefix}
																	disabled={field?.disabled || ""}
																	type="text"
																	maxLength={12}
																	onKeyPress={(e) => {
																		if (!/[0-9+]/.test(e.key) || (e.key === "+" && e.target.value.includes("+"))) {
																			e.preventDefault();
																		}
																	}}
																	onPaste={(e) => {
																		const paste = (e.clipboardData || window.clipboardData).getData("text");
																		if (!/^[0-9+]+$/.test(paste) || (paste.indexOf("+") > 0)) {
																			e.preventDefault();
																		}
																	}}
																/>
															</FormItem>
															{shouldDelete ? (
																<span className="deleteIcon">
																	<HiOutlineTrash
																		style={{ cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
																		onClick={!isDeleteSave ? () => {
																			onDelete(field?.id);
																		} : null}
																	/>
																</span>
															) : ''}
														</>
													}

													{((field?.component === "input" || !field?.component) && field?.type !== "phone") &&
														<>
															<FormItem
																label={field?.label}
																name={field?.name}
																rules={field?.rules}
																dependencies={
																	field?.dependencies
																		? [field?.dependencies]
																		: undefined
																}
															>
																{field?.type === "password" ? (
																	<Input.Password
																		placeholder={field?.placeholder}
																		defaultValue={field?.defaultValue}
																		className="form-control"
																		prefix={field?.prefix}
																		disabled={field?.disabled || ""}
																		type={field?.type}
																	/>
																) : (
																	<Input
																		placeholder={field?.placeholder}
																		className="form-control"
																		defaultValue={field?.defaultValue}
																		disabled={field?.disabled || ""}
																		prefix={
																			field?.prefix ? (
																				field?.prefix
																			) : (
																				<RiContactsLine
																					style={{
																						color: colors?.primary || "#F47A3A",
																						fontSize: "25px",
																					}}
																				/>
																			)
																		}
																		type={field?.type}
																	/>
																)}
															</FormItem>
															{shouldDelete ? (
																<span className="deleteIcon">
																	<HiOutlineTrash
																		style={{ cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
																		onClick={!isDeleteSave ? () => {
																			onDelete(field?.id);
																		} : null}
																	/>
																</span>
															) : ''}
														</>
													}
													{field?.component === "select" && (
														<>
															{field?.label !== 'Utility Company' ? (
																<FormItem
																	label={field?.label}
																	name={field?.name}
																	rules={field?.rules}
																>
																	<Select
																		id={index}
																		dropdownRender={(originNode) => (
																			<div
																				style={{
																					padding: "0px",
																					display:
																						field?.name === "category_id" ||
																							field?.name === "frequency" ||
																							field?.name === "provider_id" ||
																							field?.name === "company_id"
																							? "block"
																							: dropdownVisible
																								? "block"
																								: "none",
																				}}
																			>
																				{originNode}
																			</div>
																		)}
																		className="formControl select-custom "
																		disabled={field.disabled || ""}
																		showSearch
																		onSelect={() => setDropdownVisible(false)}
																		onSearch={handleDropdownVisibleChange}
																		filterOption={(input, option) =>
																			(option?.label ?? "")
																				.toLowerCase()
																				.includes(input.toLowerCase())
																		}
																		mode={field.mode || ""}
																		optionLabelProp="label"
																		placeholder={field?.placeholder}
																		options={field?.options}
																		suffixIcon={field?.suffixIcon}
																		onChange={(value) => handleFieldChange(field, value, onUtilityTypeChange, setFormValues)}
																		optionRender={(option) => (
																			<Space>
																				<span role="img" aria-label={option.data.value ? option.data.value : option.value}>
																					{option.data.label ? option.data.label : option.label}
																				</span>
																			</Space>
																		)}
																	/>
																</FormItem>
															) : (
																<FormItem
																	label={field?.label}
																	name={field?.name}
																	rules={field?.rules}
																>
																	<Select
																		id={index}
																		dropdownRender={(originNode) => (
																			<div>
																				{showDropdown &&
																					field?.options?.some((option) =>
																						option.label.toLowerCase().includes(inputValue.toLowerCase())
																					) &&
																					originNode}
																				{inputValue &&
																					!field?.options?.some(
																						(option) =>
																							option.label.toLowerCase() === inputValue.toLowerCase()
																					) && (
																						<div
																							style={{
																								display: "flex",
																								justifyContent: "space-between",
																								padding: "8px 12px",
																								borderTop: "1px solid #f0f0f0",
																								cursor: "pointer",
																							}}
																							onClick={() => {
																								setShowDropdown(false);
																								handleAddUtilityCompany(inputValue)
																									.then(() => {
																										setShowDropdown(true);
																										setInputValue("");
																									})
																									.catch((error) => {
																										console.error("Error adding company:", error);
																										setShowDropdown(true);
																									});
																							}}
																						>
																							<span className="d-flex customAddWrapper">
																								<div className="btnDiv">
																									<Button>Add</Button>
																								</div>
																								<div className="valueDiv">{inputValue}</div>
																							</span>
																						</div>
																					)}
																			</div>
																		)}
																		className="formControl select-custom"
																		disabled={field.disabled || ""}
																		showSearch
																		onSearch={(value) => setInputValue(value)}
																		filterOption={(input, option) =>
																			(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
																		}
																		mode={field.mode || ""}
																		optionLabelProp="label"
																		placeholder={field?.placeholder}
																		options={field?.options}
																		suffixIcon={field?.suffixIcon}
																		onChange={(value) =>
																			handleFieldChange(field, value, onUtilityTypeChange, setFormValues)
																		}
																		optionRender={(option) => (
																			<Space>
																				<span
																					role="img"
																					aria-label={option.data.value ? option.data.value : option.value}
																				>
																					{option.data.label ? option.data.label : option.label}
																				</span>
																			</Space>
																		)}
																	/>
																</FormItem>
															)}
														</>

													)}
													{field?.component === "checkbox" && (
														<Row justify="space-between">
															<Col span={12}>
																<FormItem name={field?.name} valuePropName="checked">
																	<Checkbox
																		className="rememberMe"
																		onChange={(value) => {
																			setFormValues((prevValues) => ({
																				...prevValues,
																				[field.name]: value,
																			}));
																			if (field?.onChange) {
																				field?.onChange(value);
																			}
																		}}
																	>
																		{field?.label}
																	</Checkbox>
																</FormItem>
															</Col>
														</Row>
													)}
												</Col>
											</>
										)
									);
								})}
							</Row>
							<Row gutter={16}>
								{dynamicfields && dynamicfields !== '' && dynamicfields?.map((field, index) => {
									const isHidden = propertyProvider ? false : field?.hidden
										? typeof field?.hidden === "function"
											? field?.hidden()
											: field?.hidden
										: "";

									const shouldDelete =
										location.pathname === "/property_details" && !field?.static && formType == "add";

									return (
										!isHidden && (
											<>
												<Col
													key={field?.name}
													md={field?.colSpan || 24}
													offset={field?.offset || 0}
												>

													{((field?.component === "input" || !field?.component) && field?.type === "phone") &&
														<>
															<FormItem
																label={field?.label}
																name={field?.name}
																rules={[
																	{
																		required: true,
																		message: "Please enter phone number.",
																	},
																	({ getFieldValue }) => ({
																		validator(_, value) {
																			const initialPhoneNumber = getFieldValue(`${field?.name}`);
																			if (initialPhoneNumber === value) {
																				return Promise.resolve();
																			}
																			if (value && value.length === 12) {
																				return Promise.resolve();
																			}

																			return Promise.reject(new Error("Phone number must be exactly 12 digits."));
																		},
																	}),
																]}
																dependencies={field?.dependencies ? [field?.dependencies] : undefined}
															>
																<Input
																	placeholder={field?.placeholder}
																	defaultValue={field?.defaultValue}
																	className="form-control"
																	prefix={field?.prefix}
																	disabled={field?.disabled || ""}
																	type="text"
																	maxLength={12}
																	onKeyPress={(e) => {
																		if (!/[0-9+]/.test(e.key) || (e.key === "+" && e.target.value.includes("+"))) {
																			e.preventDefault();
																		}
																	}}
																	onPaste={(e) => {
																		const paste = (e.clipboardData || window.clipboardData).getData("text");
																		if (!/^[0-9+]+$/.test(paste) || (paste.indexOf("+") > 0)) {
																			e.preventDefault();
																		}
																	}}
																/>
															</FormItem>


															{shouldDelete ? (
																<span className="deleteIcon">
																	<HiOutlineTrash
																		style={{ cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
																		onClick={!isDeleteSave ? () => {
																			onDelete(field?.id);
																		} : null}
																	/>
																</span>
															) : ''}
														</>
													}

													{((field?.component === "input" || !field?.component) && field?.type !== "phone") &&
														<>
															<FormItem
																label={field?.label}
																name={field?.name}
																rules={field?.rules}
																dependencies={
																	field?.dependencies
																		? [field?.dependencies]
																		: undefined
																}
															>
																{field?.type === "password" ? (
																	<Input.Password
																		placeholder={field?.placeholder}
																		defaultValue={field?.defaultValue}
																		className="form-control"
																		prefix={field?.prefix}
																		disabled={field?.disabled || ""}
																		type={field?.type}
																	/>
																) : (
																	<Input
																		placeholder={field?.placeholder}
																		className="form-control"
																		defaultValue={field?.defaultValue}
																		disabled={field?.disabled || ""}
																		prefix={
																			field?.prefix ? (
																				field?.prefix
																			) : (
																				<RiContactsLine
																					style={{
																						color: colors?.primary || "#F47A3A",
																						fontSize: "25px",
																					}}
																				/>
																			)
																		}
																		type={field?.type}
																	/>
																)}
															</FormItem>
															{shouldDelete ? (
																<span className="deleteIcon">
																	<HiOutlineTrash
																		style={{ cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
																		onClick={!isDeleteSave ? () => {
																			onDelete(field?.id);
																		} : null}
																	/>
																</span>
															) : ''}
														</>
													}

													{field?.component === "select" && (
														<FormItem
															label={field?.label}
															name={field?.name}
															rules={field?.rules}
														>
															<Select
																id={index}
																dropdownRender={(originNode) => (
																	<div
																		style={{
																			padding: "0px",
																			display:
																				field?.name === "category_id" ||
																					field?.name === "frequency" ||
																					field?.name === "provider_id" ||
																					field?.name === "company_id"
																					? "block"
																					: dropdownVisible
																						? "block"
																						: "none",
																		}}
																	>
																		{originNode}
																	</div>
																)}
																className="formControl select-custom "
																disabled={field.disabled || ""}
																showSearch
																onSelect={() => setDropdownVisible(false)}
																onSearch={handleDropdownVisibleChange}
																filterOption={(input, option) =>
																	(option?.label ?? "")
																		.toLowerCase()
																		.includes(input.toLowerCase())
																}
																mode={field.mode || ""}
																optionLabelProp="label"
																placeholder={field?.placeholder}
																options={field?.options}
																suffixIcon={field?.suffixIcon}
																onChange={(value) => {
																	setFormValues((prevValues) => ({
																		...prevValues,
																		[field?.name]: value,
																	}));
																	if (field?.onChange) {
																		field?.onChange(value);
																	}
																	if (
																		field?.name === "provider_id" &&
																		onUtilityTypeChange
																	) {
																		onUtilityTypeChange(value);
																	}
																}}
																optionRender={(option) => (
																	<Space>
																		<span role="img" aria-label={option.data.value}>
																			{option.data.label}
																		</span>
																	</Space>
																)}
															/>
														</FormItem>
													)}

													{field?.component === "checkbox" && (
														<Row justify="space-between">
															<Col span={12}>
																<FormItem name={field?.name} valuePropName="checked">
																	<Checkbox
																		className="rememberMe"
																		onChange={(value) => {
																			setFormValues((prevValues) => ({
																				...prevValues,
																				[field.name]: value,
																			}));
																			if (field?.onChange) {
																				field?.onChange(value);
																			}
																		}}
																	>
																		{field?.label}
																	</Checkbox>
																</FormItem>
															</Col>
														</Row>
													)}

												</Col>
											</>
										)
									);
								})}
							</Row>

							<Row gutter={[16, 16]}>
								{hiddenField ? (<></>) : (
									<>
										{renderExpense()}
									</>
								)}
							</Row>

							<Row className="formButtons">
								<Col md={24} className="text-center mt-3">
									{anotherButton && <>{anotherButton}</>}
									<Button
										type="primary"
										htmlType="submit"
										className={anotherButton ? "btnFilled ms-3 w-150" : "authBtn"}
										disabled={loading ? loading : ButtonDisable}
									>
										{loading ? "Loading..." : "Save"}
									</Button>
								</Col>
							</Row>
						</Form>
					) : (
						<Form
							form={form}
							layout="vertical"
							onFinish={handleFinish}
							onValuesChange={handleValuesChange}
							initialValues={FormInitialValues ? FormInitialValues : formValues}
						>
							<Row gutter={16}>
								{allFields.map((field, index) => {
									const isHidden = field?.hidden
										? typeof field?.hidden === "function"
											? field?.hidden()
											: field?.hidden
										: "";

									const shouldDelete =
										location.pathname === "/property_details" && !field?.static && formType == "add";
									return (
										!isHidden && (
											<Col
												key={field?.name}
												md={field?.colSpan || 24}
												offset={field?.offset || 0}
											>
												{((field?.component === "input" || !field?.component) && field?.type === "phone") &&
													<>
														<FormItem
															label={field?.label}
															name={field?.name}
															rules={[
																{
																	required: true,
																	message: "Please enter phone number.",
																},
																({ getFieldValue }) => ({
																	validator(_, value) {
																		const initialPhoneNumber = getFieldValue(`${field?.name}`);
																		if (initialPhoneNumber === value) {
																			return Promise.resolve();
																		}
																		if (value && value.length === 12) {
																			return Promise.resolve();
																		}

																		return Promise.reject(new Error("Phone number must be exactly 12 digits."));
																	},
																}),
															]}
															dependencies={field?.dependencies ? [field?.dependencies] : undefined}
														>
															<Input
																placeholder={field?.placeholder}
																defaultValue={field?.defaultValue}
																className="form-control"
																prefix={field?.prefix}
																disabled={field?.disabled || ""}
																type="text"
																maxLength={12}
																onKeyPress={(e) => {
																	if (!/[0-9+]/.test(e.key) || (e.key === "+" && e.target.value.includes("+"))) {
																		e.preventDefault();
																	}
																}}
																onPaste={(e) => {
																	const paste = (e.clipboardData || window.clipboardData).getData("text");
																	if (!/^[0-9+]+$/.test(paste) || (paste.indexOf("+") > 0)) {
																		e.preventDefault();
																	}
																}}
															/>
														</FormItem>
														{shouldDelete ? (
															<span className="deleteIcon">
																<HiOutlineTrash
																	style={{ cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
																	onClick={!isDeleteSave ? () => {
																		onDelete(field?.id);
																	} : null}
																/>
															</span>
														) : ''}
													</>
												}
												{((field?.component === "input" || !field?.component) && field?.type !== "phone") &&
													<>
														<FormItem
															label={field?.label}
															name={field?.name}
															rules={field?.rules}
															dependencies={
																field?.dependencies
																	? [field?.dependencies]
																	: undefined
															}
														>
															{field?.type === "password" ? (
																<Input.Password
																	placeholder={field?.placeholder}
																	defaultValue={field?.defaultValue}
																	className="form-control"
																	prefix={field?.prefix}
																	disabled={field?.disabled || ""}
																	type={field?.type}
																/>
															) : (
																<Input
																	placeholder={field?.placeholder}
																	className="form-control"
																	defaultValue={field?.defaultValue}
																	disabled={field?.disabled || ""}
																	prefix={
																		field?.prefix ? (
																			field?.prefix
																		) : (
																			<RiContactsLine
																				style={{
																					color: colors?.primary || "#F47A3A",
																					fontSize: "25px",
																				}}
																			/>
																		)
																	}
																	type={field?.type}
																/>
															)}
														</FormItem>
														{shouldDelete ? (
															<span className="deleteIcon">
																<HiOutlineTrash
																	style={{ cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
																	onClick={!isDeleteSave ? () => {
																		onDelete(field?.id);
																	} : null}
																/>
															</span>
														) : ''}
													</>
												}
												{field?.component === "select" && (
													<FormItem
														label={field?.label}
														name={field?.name}
														rules={field?.rules}
													>
														<Select
															id={index}
															dropdownRender={(originNode) => (
																<div
																	style={{
																		padding: "0px",
																		display:
																			field?.name === "category_id" ||
																				field?.name === "frequency" ||
																				field?.name === "provider_id" ||
																				field?.name === "company_id"
																				? "block"
																				: dropdownVisible
																					? "block"
																					: "none",
																	}}
																>
																	{originNode}
																</div>
															)}
															className="formControl select-custom "
															disabled={field.disabled || ""}
															showSearch
															onSelect={() => setDropdownVisible(false)}
															onSearch={handleDropdownVisibleChange}
															filterOption={(input, option) =>
																(option?.label ?? "")
																	.toLowerCase()
																	.includes(input.toLowerCase())
															}
															mode={field.mode || ""}
															optionLabelProp="label"
															placeholder={field?.placeholder}
															options={field?.options}
															suffixIcon={field?.suffixIcon}
															onChange={(value) => {
																setFormValues((prevValues) => ({
																	...prevValues,
																	[field?.name]: value,
																}));
																if (field?.onChange) {
																	field?.onChange(value);
																}
																if (
																	field?.name === "provider_id" &&
																	onUtilityTypeChange
																) {
																	onUtilityTypeChange(value);
																}
															}}
															optionRender={(option) => (
																<Space>
																	<span role="img" aria-label={option.data.value}>
																		{option.data.label}
																	</span>
																</Space>
															)}
														/>
													</FormItem>
												)}
												{field?.component === "checkbox" && (
													<Row justify="space-between">
														<Col span={12}>
															<FormItem name={field?.name} valuePropName="checked">
																<Checkbox
																	className="rememberMe"
																	onChange={(value) => {
																		setFormValues((prevValues) => ({
																			...prevValues,
																			[field.name]: value,
																		}));
																		if (field?.onChange) {
																			field?.onChange(value);
																		}
																	}}
																>
																	{field?.label}
																</Checkbox>
															</FormItem>
														</Col>
													</Row>
												)}
											</Col>
										)
									);
								})}
							</Row>
							<Row className="formButtons">
								<Col md={24} className="text-center mt-3">
									{anotherButton && <>{anotherButton}</>}
									<Button
										type="primary"
										htmlType="submit"
										className={anotherButton ? "btnFilled ms-3 w-150" : "authBtn"}
										disabled={loading ? loading : ButtonDisable}
									>
										{loading ? "Loading..." : buttonName}
									</Button>
									{saveAndExit && <>{saveAndExit}</>}
								</Col>
							</Row>
						</Form>
					)}
				</>
			)}
		</>
	);
};

export default PropertyDetailsForm;
