import React, { useState } from "react";
import { Form, Input, Button, Alert } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useForgotPasswordMutation } from "../../slices/loginRegisterSlice";
import { saveEmail } from "../../slices/authSlice";
import images from "../../constant/images";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const { Logo, SignInImage } = images;

  const onFinish = async (values) => {
    try {
      const response = await forgotPassword(values);
      if (response.data) {
        setSuccessMessage(response.data.message);
        dispatch(saveEmail({ email: values }));
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      } else {
        setErrorMessage(response.error.data.message);
      }
    } catch (error) {
      setErrorMessage("User not found");
    }
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  };

  return (
      <div className="authWrapper">
        <div className="authInner">
          <div className="container-fluid h-100">
            <div className="row h-100">
              <div className="col-md-6 whiteBg">
                <div className="formWrapper">
                  <div className="logoWrap">
                    <Logo />
                  </div>
                  <div className="titleWrap">
                    <h2 className="authTitle">Forgot Password</h2>
                  </div>
                  <div className="formWrap">
                    {errorMessage && (
                        <Alert
                            message={errorMessage}
                            type="error"
                            showIcon
                            style={{ marginBottom: 16 }}
                        />
                    )}
                    {successMessage && (
                        <Alert
                            message={successMessage}
                            type="success"
                            showIcon
                            style={{ marginBottom: 16 }}
                        />
                    )}

                    <Form
                        form={form}
                        name="forgot_password"
                        onFinish={onFinish}
                        layout="vertical"
                        autoComplete="off"
                    >
                      <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please input your email",
                            },
                            {
                              type: "email",
                              message: "Please enter a valid email"
                            }
                          ]}
                      >
                        <Input
                            prefix={<MailOutlined style={{ color: "#F47939", fontSize: "20px" }} />}
                            placeholder="Enter Email"
                            size="large"
                        />
                      </Form.Item>

                      <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                            block
                            size="large"
                            style={{
                              backgroundColor: "#F47939"
                            }}
                        >
                          Send Link
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mobHide">
                <div className="imgWrapper">
                  <SignInImage />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ForgotPassword;