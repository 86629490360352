import React, { useState } from "react";
import {
    Button,
    Form,
    Input,
    Select,
    Row,
    Col,
    DatePicker,
    Space,
} from "antd";
import { RiContactsLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';

const DynamicForm = ({
    form,
    fields,
    onFinish,
    buttonName,
    loading,
    ButtonDisable,
    FormInitialValues = {},
}) => {
    const [formValues, setFormValues] = useState({});
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const [selectedDate, setSelectedDate] = useState();

    const preprocessFormInitialValues = (values) => {
        return {
            ...values,
            start_date: values.start_date ? dayjs(values.start_date, 'YYYY-MM-DD') : null,
            end_date: values.end_date ? dayjs(values.end_date, 'YYYY-MM-DD') : null,
        };
    };

    const handleDropdownVisibleChange = (value) => {
        if (value) {
            setDropdownVisible(true);
        } else {
            setDropdownVisible(false);
        }
    };

    const handleValuesChange = (allValues) => {
        if (allValues) {
            setFormValues(allValues);
        }
    };

    const handleFinish = (allValues) => {
        onFinish(allValues);
    };

    const handleFieldChange = (field, value, setFormValues) => {
        const updatedFormValues = {
            ...formValues,
            [field?.name]: value?.format ? value.format("YYYY-MM-DD") : value, // Format date to string
        };

        setFormValues(updatedFormValues);

        if (field?.onChange) {
            field.onChange(value);
        }
    };

    const disabledDate = (current) => {
        return current && current.year() > dayjs().year();
    };

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFinish}
                onValuesChange={handleValuesChange}
                initialValues={FormInitialValues ? preprocessFormInitialValues(FormInitialValues) : formValues}
            >
                <Row gutter={16}>
                    {fields.map((field, index) => {
                        const isHidden = field?.hidden
                            ? typeof field?.hidden === "function"
                                ? field?.hidden()
                                : field?.hidden
                            : "";

                        return (
                            <>
                                <Col
                                    key={field?.name}
                                    md={field?.colSpan || 24}
                                    offset={field?.offset || 0}
                                >

                                    {((field?.component === "input" || !field?.component) &&
                                        field?.type !== "date") && (
                                            <>
                                                <Form.Item
                                                    label={field?.label}
                                                    name={field?.name}
                                                    rules={field?.rules}
                                                    dependencies={
                                                        field?.dependencies
                                                            ? [field?.dependencies]
                                                            : undefined
                                                    }
                                                >
                                                    <Input
                                                        placeholder={field?.placeholder}
                                                        className="form-control"
                                                        defaultValue={field?.defaultValue}
                                                        disabled={field?.disabled || ""}
                                                        prefix={
                                                            field?.prefix ? (
                                                                field?.prefix
                                                            ) : (
                                                                <RiContactsLine
                                                                    style={{
                                                                        color: colors?.primary || "#F47A3A",
                                                                        fontSize: "25px",
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                        type={field?.type}
                                                    />
                                                </Form.Item>
                                            </>
                                        )}

                                    {field?.type === "date" && (
                                        <Form.Item
                                            label={field?.label}
                                            name={field?.name}
                                            rules={field?.rules}
                                        >
                                            <DatePicker
                                                key={index}
                                                format="MM/DD/YYYY"
                                                className="form-control"
                                                placeholder={field?.placeholder}
                                                onChange={(date) =>
                                                    handleFieldChange(field, date, setFormValues)
                                                }
                                                value={selectedDate}
                                                disabled={field?.disabled || ""}
                                                disabledDate={disabledDate}
                                            />
                                        </Form.Item>
                                    )}

                                    {field?.component === "select" && (
                                        <Form.Item
                                            label={field?.label}
                                            name={field?.name}
                                            rules={field?.rules}
                                        >
                                            <Select
                                                id={index}
                                                dropdownRender={(originNode) => (
                                                    <div
                                                        style={{
                                                            padding: "0px",
                                                            display:
                                                                field?.name === "category_id" ||
                                                                    field?.name === "frequency" ||
                                                                    field?.name === "provider_id" ||
                                                                    field?.name === "company_id"
                                                                    ? "block"
                                                                    : dropdownVisible
                                                                        ? "block"
                                                                        : "none",
                                                        }}
                                                    >
                                                        {originNode}
                                                    </div>
                                                )}
                                                className="formControl select-custom "
                                                disabled={field.disabled || ""}
                                                showSearch
                                                onSelect={() => setDropdownVisible(false)}
                                                onSearch={handleDropdownVisibleChange}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? "")
                                                        .toLowerCase()
                                                        .includes(input.toLowerCase())
                                                }
                                                mode={field.mode || ""}
                                                optionLabelProp="label"
                                                placeholder={field?.placeholder}
                                                options={field?.options}
                                                suffixIcon={field?.suffixIcon}
                                                onChange={(value) => handleFieldChange(field, value, setFormValues)}
                                                optionRender={(option) => (
                                                    <Space>
                                                        <span role="img" aria-label={option.data.value ? option.data.value : option.value}>
                                                            {option.data.label ? option.data.label : option.label}
                                                        </span>
                                                    </Space>
                                                )}
                                            />
                                        </Form.Item>
                                    )}
                                </Col>
                            </>
                        );
                    })}
                </Row>

                <Row className="formButtons">
                    <Col md={24} className="text-center mt-3">
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="authBtn"
                            disabled={loading ? loading : ButtonDisable}
                        >
                            {loading ? "Loading..." : "Save"}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default DynamicForm;
