import React, { useEffect, useRef, useState } from "react";
import Title from "../../component/PageTitle";
import { Button, Form, Input, Modal, message, Spin } from "antd";
import { RiUser2Line } from "react-icons/ri";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import { SlCalender } from "react-icons/sl";
import PropertyDetailsForm from "../../component/Form/PropertyDetailsForm";
import { IoHomeOutline } from "react-icons/io5";
import { LiaUserEditSolid } from "react-icons/lia";
import { BsPlusCircle } from "react-icons/bs";
import CommonModal from "../../component/CommonModal";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import {
    useGetCompanyQuery,
    useGetUtilityQuery,
    useGetFieldsQuery,
    useGetPropertyProvidersQuery,
    useGetCategoryQuery,
    useGetLabelsQuery,
} from "../../slices/getSlice";
import {
    useAddPropertyProviderMutation,
    useAddUtilityMutation,
    useAddCompanyMutation,
    useAddFieldMutation,
    useUpdateLabelsMutation,
    useEditPropertyProviderMutation,
    useDeleteFieldMutation,
    useGetPropertyProviderByIdMutation,
} from "../../slices/apiSlice";
import { toast } from "react-toastify";
import { PiHouseLine } from "react-icons/pi";
import { UserOutlined } from "@ant-design/icons";

const PropertyDetails = () => {
    const [form] = Form.useForm();
    const [labelForm] = Form.useForm();
    const navigate = useNavigate()
    const [utility, setUtility] = useState();
    const [company, setCompany] = useState();
    const location = useLocation();
    const { loading, setLoading } = useOutletContext();
    const [selectedUtilityType, setSelectedUtilityType] = useState(null);
    const [FormInitialValues, setFormInitialValues] = useState(location?.state?.data);
    const [category, setCategory] = useState();
    const [FormInitialValuesDiff, setFormInitialValuesDiff] = useState({
        name: ""
    });
    const isEdit = location?.state?.isEdit;
    const [labelsModal, setLabelsModal] = useState(false);
    let propertyDetailState = location?.state
    const [addPropertyProvider, { isLoading: isLoadingAdd }] = useAddPropertyProviderMutation();
    const [editPropertyProvider, { isLoading: isLoadingEdit }] = useEditPropertyProviderMutation();
    const [addUtility, { isLoading: isLoadingAddProvider }] = useAddUtilityMutation();
    const [addCompany, { isLoading: isLoadingAddCompany }] = useAddCompanyMutation();
    const [addField, { isLoading: isLoadingAddField }] = useAddFieldMutation();
    const [deleteField] = useDeleteFieldMutation()
    const [getPropertyProviderById] = useGetPropertyProviderByIdMutation()
    const [modal1] = Form.useForm();
    const [modal2] = Form.useForm();
    const [formKey, setFormKey] = useState(0);
    const [isSaving, setIsSaving] = useState(false)
    const [labelUpdate, { isLoading: isLoadingUpdate }] = useUpdateLabelsMutation();
    const [isDeleteSave, setIsDeleteSave] = useState(false)
    const [expenseType, setExpenseType] = useState('')
    const [propertyProvider, setPropertyProvider] = useState();
    const [addNew, setAddNew] = useState(false);

    let detail = {
        propertyId: location?.state?.data?.property_id,
        providerTypesId: location?.state?.providerType,
        skip: location?.state?.data?.property_id && location?.state?.providerType
    };

    const fetchPropertyProvider = () => {
        getPropertyProviderById(location?.state?.data?.id).then((response) => {
            setPropertyProvider(response?.data)
        }).catch((error) => {
            console.error({ error })
        })
    }

    const {
        data: getFields = [],
        isSuccess: isSuccessFields,
        refetch: refetchFields,
    } = useGetFieldsQuery(selectedUtilityType ? selectedUtilityType : propertyProvider?.provider?.id ? propertyProvider?.provider?.id : location?.state?.data?.providerId ? location?.state?.data?.providerId : location?.state?.data?.id);

    useEffect(() => {
        if (location?.state?.data?.id && location?.state?.isEdit === true && isSuccessFields) {
            fetchPropertyProvider();
        }
    }, [isSuccessFields]);

    const {
        data: getPropertyProviders,
        refetch: refetchPropertyProvider,
    } = useGetPropertyProvidersQuery(detail ? detail : "");

    const {
        data: getUtility,
        refetch: refetchUtility,
    } = useGetUtilityQuery(propertyProvider?.provider ? propertyProvider?.provider?.provider_type_id : propertyProvider?.provider_type_id ? propertyProvider?.provider_type_id : location?.state?.data.id ? location?.state?.data.id : location?.state?.providerType[0]);

    const {
        data: getCompany,
        refetch: refetchCompany,
    } = useGetCompanyQuery(selectedUtilityType ? selectedUtilityType : propertyProvider?.provider?.id ? propertyProvider?.provider?.id : location?.state?.data?.providerId ? location?.state?.data?.providerId : location?.state?.data?.id);

    const {
        data: getCategory,
        refetch: refetchCategory,
    } = useGetCategoryQuery('expense');

    const { data: labelData, refetch: refetchLabels } = useGetLabelsQuery('expense_form');
    const [disable, setDisable] = useState(location?.state?.data?.property_id ? false : true);
    const [modalfield, setModalField] = useState(false);
    const [modalprovider, setModalProvider] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [compnayID, setCompanyID] = useState(null);
    const [filteredOptions, setFilteredOptions] = useState('Old data');

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const formatUtilityandCategory = () => {
        if (getUtility && getUtility?.data) {
            const transformedOptions = getUtility?.data?.map((item) => ({
                label: item.provider_name,
                value: item.id,
            }));

            const sortedOptions = transformedOptions?.sort((a, b) =>
                a?.label.toLowerCase().localeCompare(b?.label.toLowerCase())
            );

            if (sortedOptions?.length > 0) {

                setUtility((prevUtility) =>
                    JSON.stringify(prevUtility) !== JSON.stringify(sortedOptions)
                        ? sortedOptions
                        : prevUtility
                );
            }
        }

        // Handle category transformation
        if (getCategory && getCategory?.data) {
            const transformedOptions = getCategory.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));

            if (transformedOptions?.length > 0) {
                setCategory((prevCategory) =>
                    JSON.stringify(prevCategory) !== JSON.stringify(transformedOptions)
                        ? transformedOptions
                        : prevCategory
                );
            }
        }
    };

    // Run only when `getUtility` or `getCategory` changes
    useEffect(() => {
        formatUtilityandCategory();
        setLoading(false);
    }, [getUtility, getCategory]);

    useEffect(() => {
        refetchPropertyProvider();
    }, [getPropertyProviders]);

    useEffect(() => {
        if (getCompany && getCompany?.data) {
            const companies = getCompany?.data?.map((item) => ({
                label: item.company_name,
                value: item.company_id,
            }));
            const sortedOptions = companies?.sort((a, b) =>
                a?.label.toLowerCase().localeCompare(b?.label.toLowerCase())
            );
            setCompany(sortedOptions);
        }
    }, [getCompany]);

    useEffect(() => {
        if (expenseType) {
            const filtered = category.filter((option) => option.value === expenseType);
            setFilteredOptions(filtered[0]?.label);
        } else {
            setFilteredOptions('')
        }
    }, [expenseType]);

    const newFields = [
        {
            component: "input",
            type: "text",
            label: `Enter Field Name`,
            name: "name",
            placeholder: `Enter Field Name`,
            prefix: (
                <LiaUserEditSolid style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: `Please input your field name`,
                },
                {
                    pattern: /^(?!\s)[A-Za-z\s]+(?<!\s)$/,
                    message: '  Field name  must only contain letters',
                },
            ],
        },
        {
            component: "select",
            label: "Select Type",
            name: "field_type",
            placeholder: "Please select type",
            suffixIcon: (
                <IoHomeOutline
                    style={{
                        color: "#F47939",
                        fontSize: "25px",
                        width: "30px",
                        height: "30px",
                    }}
                />
            ),
            rules: [
                {
                    required: true,
                    message: "Please select type",
                },
            ],
            options: [
                { label: "Date", value: "date" },
                // { label: "Email", value: "email" },
                // { label: "Number", value: "number" },
                { label: "Password", value: "password" },
                { label: "Phone Number", value: "phone" },
                { label: "Text", value: "text" },
                // { label: "Url", value: "url" },
            ],
        },
    ];

    const providerFields = [
        {
            component: "input",
            type: "text",
            label: `Enter  Utility Name`,
            name: "name",
            placeholder: `Enter Utility Name`,
            prefix: (
                <LiaUserEditSolid style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: `Please input your utility name`,
                },
                {
                    pattern: /^(?!\s)[A-Za-z\s]+(?<!\s)$/,
                    message: '  Utility name  must only contain letters',
                },
            ],
        },
    ];

    const dynamicValue = FormInitialValues?.form_fields ?? location?.state?.data?.form_fields

    const renderFormFields = () => {
        if (!dynamicValue) {
            return [];
        } else {
            let parsedDynamicValue = dynamicValue;

            const isEmpty = (parsedDynamicValue) => {
                return Object.keys(parsedDynamicValue).length === 0;
            };

            if (!isEmpty(parsedDynamicValue)) {

                return Object.keys(parsedDynamicValue).map((key) => {

                    const fieldValue = parsedDynamicValue[key];
                    return {
                        component: "input",
                        name: key,
                        type: 'text',
                        label: key,
                        defaultValue: fieldValue,
                        colSpan: 8,
                        static: false,
                    };
                });
            } else {
                return [];
            }
        }
    };

    const dynamicfields = getFields?.data?.length && (isEdit || addNew === true || addNew === 'true')
        ? getFields.data.map((item) => {
            const rules = item.field_type === 'phone' ? [
                {
                    pattern: /^\+?[1-9]\d{0,2}\s?\d{10}$/,
                    message: `${item.name} must be a valid phone number with an optional country code`,
                },
            ] : item.field_type === 'text' ? [
                {
                    pattern: /^[A-Za-z\s]+$/,
                    message: 'It can only contain letters and spaces',
                },
                {
                    max: 25,
                    message: 'It cannot exceed 25 characters',
                },
            ] : [];


            return {
                component: "input",
                type: (item.field_type === 'url') ? "text" : item.field_type === 'phone' ? "phone" : item.field_type,
                label: item.name,
                name: item.name,
                placeholder: `Enter ${item.name}`,
                colSpan: 8,
                static: false,
                id: item?.id,
                rules,
            };
        })
        : null;

    const openLabelModal = () => {
        setLabelsModal(true);
    };

    const handleLabelModal = () => {
        setLabelsModal(false);
    };

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };

    const fields = [
        {
            component: "select",
            label: capitalizeFirstLetter(labelData?.provider_id) || "Type of Servies",
            name: "provider_id",
            disabled: location?.state?.data?.property_id && location?.state?.data?.provider_id ? true : false,
            placeholder: "Select type of service",
            suffixIcon: (
                <RiUser2Line style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            onChange: (value) => {
                setFormInitialValues({
                    company_id: null
                })
                setDisable(false);
                setSelectedUtilityType(value);
            },
            rules: [
                {
                    required: true,
                    message: "Please select utility type",
                },
            ],
            options: utility,
            colSpan: 12,
            static: true,
        },
        {
            component: "select",
            label: capitalizeFirstLetter(labelData?.company_id) || "Utility Company",
            name: "company_id",
            placeholder: "Select Company",
            // hidden: disable, Commented for now
            hidden: false,
            suffixIcon: (
                <RiUser2Line style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            options: company,
            colSpan: 12,
            static: true,
        },
        {
            component: "select",
            label: capitalizeFirstLetter(labelData?.category_id) || "Expense Type",
            name: "category_id",
            placeholder: "Please select type",
            rules: [
                {
                    required: true,
                    message: "Please select type",
                },
            ],
            disabled: location?.state?.data?.property_id ? true : false,
            onChange: (value) => {
                setExpenseType(value)

            },
            suffixIcon: (
                <PiHouseLine style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            options: category,
            hidden: disable,
            colSpan: 8,
            static: true,
        },
        {
            component: "input",
            type: "number",
            label: capitalizeFirstLetter(labelData?.amount) || "Amount",
            name: "amount",
            hidden: disable,
            placeholder: "Enter Amount",
            prefix: (
                <HiOutlineCurrencyDollar
                    style={{ color: "#F47939", fontSize: "25px" }}
                />
            ),
            rules: [

                {
                    pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                    message: 'Amount must be positive number',
                },

            ],
            colSpan: 8,
            static: true,
        },
        {
            component: "input",
            type: "date",
            label: labelData?.start_date ? capitalizeFirstLetter(labelData?.start_date) : filteredOptions === 'Recurring' ? 'Start Date' : 'Payment Date',
            name: "start_date",
            hidden: disable,
            disabled: location?.state?.data?.property_id ? true : false,
            placeholder: "Enter start date ",
            prefix: (
                <SlCalender
                    style={{ color: "#F47939", fontSize: "25px", height: "20px" }}
                />
            ),
            colSpan: 8,
            static: true,
        },
        {
            component: "input",
            type: "date",
            label: capitalizeFirstLetter(labelData?.next_payment_date) || "Next Payment Date",
            name: "next_payment_date",
            hidden: location?.state?.data?.category_id === 'Recurring' || filteredOptions === 'Recurring' ? false : true,
            disabled: location?.state?.data?.property_id ? true : false,
            placeholder: "Enter Next Payment Date ",
            prefix: (
                <SlCalender
                    style={{ color: "#F47939", fontSize: "25px", height: "20px" }}
                />
            ),
            colSpan: 12,
            static: true,
        },
        {
            component: "select",
            label: capitalizeFirstLetter(labelData?.frequency) || "Frequency",
            name: "frequency",
            hidden: filteredOptions === 'Recurring' || location?.state?.data?.category_id === 'Recurring' ? false : true,
            disabled: location?.state?.data?.property_id ? true : false,
            placeholder: "Select the Frequency",
            suffixIcon: (
                <RiUser2Line style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            options: [
                { value: "7", label: "Weekly" },
                { value: "14", label: "Fortnight" },
                { value: "30", label: "Monthly" },
                { value: "91", label: "Quarterly" },
                { value: "181", label: "Half Yearly" },
                { value: "365", label: "Yearly" },
            ],
            colSpan: 12,
            static: true,
        },
        {
            component: 'checkbox',
            type: 'checkbox',
            hidden: disable,
            name: 'reminder',
            label: 'Remind',
            static: true,
            onChange: handleCheckboxChange

        },
        {
            component: "input",
            type: "number",
            hidden: isChecked || FormInitialValues?.reminder ? false : true,
            label: capitalizeFirstLetter(labelData?.reminder_duration) || "Reminder (Please specify the day the reminder is needed on)",
            label: "Reminder (Please specify the day the reminder is needed on)",
            name: "reminder_duration",
            placeholder: "Enter Reminder",
            prefix: <UserOutlined style={{ color: "#F47939", fontSize: "25px" }} />,
            colSpan: 12,
            static: true,
        },
    ];

    const orderFieldsByLabelData = (fields, labelData) => {
        if (!labelData) return fields;

        const orderedFields = Object.keys(labelData).map((key) => {
            return fields.find(field => field.name === key);
        }).filter(Boolean);

        return orderedFields;
    };

    const sortedField = orderFieldsByLabelData(fields, labelData);

    const addExpense = async (staticValue, dynamicValue, formType) => {
        setIsSaving(true)
        try {
            const stateData = location?.state;
            let values = {};
            let company_id = null

            if (formType === "add") {
                setSelectedUtilityType(null)
                setDisable(true)

                values = {
                    provider_type_id: stateData.data.id,
                    property_id: location?.state?.propertyid,
                    form_fields: Object.keys(dynamicValue).length !== 0 ? dynamicValue : null,
                    ...staticValue,
                }
            } else {
                if (typeof staticValue.company_id === "string") {
                    let company_data = company.find((companyData) => companyData.label === staticValue.company_id);
                    company_id = company_data.value;
                } else {
                    company_id = staticValue.company_id;
                }
                values = {
                    provider_type_id: stateData?.data?.provider_type_id ? stateData?.data?.provider_type_id : stateData?.providerType[0],
                    property_id: stateData?.propertyid ? stateData?.propertyid : stateData.data.property_id ? stateData.data.property_id : stateData?.propertyData?.id,
                    company_id: company_id ? company_id : stateData?.data?.companyId,
                    form_fields: Object.keys(dynamicValue).length !== 0 ? dynamicValue : stateData?.data?.form_fields ? Object.keys(stateData?.data?.form_fields).length > 0 ? stateData?.data?.form_fields : null : '',
                    frequency: staticValue?.frequency,
                    due_date: staticValue?.due_date,
                    amount: staticValue?.amount,
                    expenses: staticValue?.expenses,
                    provider_id: stateData?.data?.providerId ?? staticValue?.provider_id,
                    category_id: staticValue?.category_id,
                    start_date: staticValue?.start_date,
                    reminder_duration: staticValue?.reminder_duration,
                    reminder: staticValue?.reminder
                };
            }

            let id = location?.state?.data?.id

            if (formType === "edit") {
                const response = await editPropertyProvider({ data: values, id });
                if (response.data) {
                    refetchPropertyProvider()
                    navigate('/view_property', {
                        state: {
                            provider_type_id: location.state.data.provider_type_id,
                            property_id: location.state.data.property_id,
                            propertyData: propertyDetailState.propertyData
                        }
                    })
                    toast.success("Property provider has been updated successfully");
                    form.resetFields();
                } else {
                    toast.error(`${response?.error?.data?.message}`);
                }
            } else {
                const response = await addPropertyProvider({ providers: [values] });
                if (response.data) {
                    toast.success("Property provider has been created successfully");
                    form.resetFields();
                    form.resetFields(["provider_id"])
                    form.resetFields(["category_id"])
                    form.resetFields(["remind"])
                    setFilteredOptions('Old Data')
                    setIsChecked(false);

                    refetchPropertyProvider()
                    form.resetFields();
                    setFilteredOptions('Old Data')
                    setIsChecked(false)
                    form.resetFields(["provider_id"]);
                    form.resetFields(["category_id"]);
                    form.resetFields(["company_id"]);
                    form.resetFields(["remind"])
                    setSelectedUtilityType(null)
                    setAddNew(false);

                    navigate('/view_property', {
                        state: {
                            propertyData: propertyDetailState.propertyData
                        }
                    });

                } else {
                    toast.error(`${response?.error?.data?.message}`);
                }
            }

        } catch (error) {
            console.error(error)
        } finally {
            setIsSaving(false);
            setFormKey((prevKey) => prevKey + 1);
            fetchPropertyProvider();
        }
    };

    const fieldAdd = async (values) => {
        let data = {
            provider_type_id: propertyProvider?.provider_type_id ? propertyProvider?.provider_type_id : location?.state?.data.id ? location?.state?.data.id : location?.state?.providerType[0],
            provider_id: propertyProvider?.provider?.id ? propertyProvider?.provider?.id : selectedUtilityType ? selectedUtilityType : location?.state?.data?.providerId ? location?.state?.data?.providerId : location?.state?.data?.id,
            ...values,
        };
        try {
            const response = await addField(data);
            if (response.data) {
                setAddNew(true);
                setModalField(false);
                refetchFields();
                modal1.resetFields();
                toast.success("Field has been created successfully");
            }
            else {
                toast.error(`${response?.error?.data?.message}`);
                modal1.resetFields();
            }
        } catch (error) {
            toast.error("Failed to add field");
        }
    };

    const providerAdd = async (values) => {
        let data = {
            provider_type_id: location?.state?.providerType?.[0] ?? location?.state?.data.id,
            ...values,
        };

        try {
            const response = await addUtility(data);
            if (response.data) {
                setModalProvider(false);
                modal2.resetFields();
                refetchUtility();
                toast.success("Utility added successfully");
            } else {
                toast.error(`${response?.error?.data?.message}`);
            }
        } catch (error) {
            toast.error("Failed to add Utility type");
        }
    };

    const companyAdd = async (values) => {
        let data = {
            provider_type_id: values.provider_type_id ? values.provider_type_id : location?.state?.data.id ? location?.state?.data.id : location?.state?.providerType[0],
            provider_id: values?.providerID ? values?.providerID : selectedUtilityType ? selectedUtilityType : location?.state?.data?.providerId ? location?.state?.data?.providerId : location?.state?.data?.id,
            name: values?.company_name
        };

        const response = await addCompany(data);
        if (response.data) {
            refetchCompany();
            setCompanyID(response?.data?.data?.company_id);
            toast.success("Company has been added successfully");
        } else {
            toast.error(`${response?.error?.data?.message}`);
        }
    };

    const saveAndExit = (
        location?.pathname === '/property_details' ? <Button
            type="primary"
            htmlType={isEdit ? "" : "submit"}
            className="btnFilled ms-3 w-150"
            onClick={() => {
                if (isEdit) {
                    navigate('/view_property', {
                        state: {
                            propertyData: propertyDetailState.propertyData
                        }
                    });
                }
            }}
        >
            {isEdit ? "Cancel" : "Save & Exit"}
        </Button> : ''
    );

    const updateLabels = async (value) => {
        const layoutData = {};
        orderedFields.forEach((field) => {
            const value = labelForm.getFieldValue(field.name);
            layoutData[field.name] = value !== undefined ? value : null;
        });
        const convertedData = {
            layout_data: layoutData
        };
        setIsSaving(true);
        try {
            await labelUpdate({ label: 'expense_form', value: convertedData }).unwrap();
            message.success('Labels updated successfully!');
            refetchLabels();
        }
        catch {
        } finally {
            setIsSaving(false);
            setLabelsModal(false);
            labelForm.resetFields();
        }
    }
    const [orderedFields, setOrderedFields] = useState(fields);

    const dragItem = useRef(null);
    const dragOverItem = useRef(null);

    const handleDragStart = (index) => {
        dragItem.current = index;
    };

    const handleDrop = () => {
        const newOrder = Array.from(orderedFields);
        const draggedItem = newOrder.splice(dragItem.current, 1)[0];
        newOrder.splice(dragOverItem.current, 0, draggedItem);

        setOrderedFields(newOrder);
        dragItem.current = null;
        dragOverItem.current = null;
    };

    const handleSubmitData = (values) => {
        updateLabels(values);
    };

    const handleDelete = async (id) => {
        setIsDeleteSave(true);
        try {
            const res = await deleteField(id);
            if (res?.data) {
                message.success('Fields deleted successfully!');
                refetchFields();
            } else {
                message.error(`${res?.error?.data?.error}`);
            }
        }
        catch {
            message.error('An error occured');
        } finally {
            setIsDeleteSave(false);
        }
    }

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}

            <div className="customPaddingProperty">
                <div className="mainWrapper">
                    <div className="fixedWrapper">
                        <div className="card titleCard mb-4 propertyTitleCard">
                            <div className="card-body d-flex justify-content-start align-items-start flex-column">
                                <Title title="Property Details" />
                                <p className="mt-3 property-name"><span className="property-name-key">Property Name</span> :- {location?.state?.propertyData?.name_of_property}</p>
                            </div>
                        </div>
                    </div>

                    <div className="scrollingWrapper">
                        <div className="card tableCard customTableCard">
                            <div className="card-body pt-0">
                                <div className="topRow">
                                    <div className="topTile">
                                        <BsPlusCircle />

                                        <h5>{`${location?.state?.data?.name
                                            ? location?.state?.data?.name
                                            : location.state?.providerTypeName
                                            } Details`}</h5>
                                    </div>
                                    <div className="buttonSection">
                                        <Button
                                            type="primary"
                                            className="btnOutlined w-150 me-3"
                                            disabled={propertyProvider ? false : (location?.state?.data?.property_id || disable) ? true : false}
                                            onClick={() => {
                                                setModalField(true);
                                                setFormInitialValuesDiff({ name: "", field_type: "" })
                                            }}
                                        >
                                            Add Field
                                        </Button>
                                        {isEdit && <Button
                                            type="primary"
                                            className="btnOutlined me-3"
                                            onClick={() => {
                                                openLabelModal()
                                            }}
                                        >
                                            Change Labels
                                        </Button>}
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            disabled={location?.state?.data?.property_id && location?.state?.data?.provider_id ? true : false}
                                            className="btnOutlined me-3"
                                            onClick={() => {
                                                setModalProvider(true);
                                                setFormInitialValuesDiff({ name: "" })
                                            }}
                                        >
                                            Add Service Provider
                                        </Button>
                                    </div>
                                </div>
                                <div className="contetnWrapper mt-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <PropertyDetailsForm
                                                key={formKey}
                                                form={form}
                                                fields={sortedField?.length === 9 ? sortedField : fields}
                                                onFinish={addExpense}
                                                buttonName={isEdit ? "Update" : "Save & Continue"}
                                                dynamicfields={
                                                    isEdit && FormInitialValues?.form_fields !== null && FormInitialValues?.form_fields !== undefined
                                                        ? renderFormFields()
                                                        : (selectedUtilityType || propertyProvider) ? dynamicfields : ''
                                                }
                                                propertyProvider={propertyProvider}
                                                onUtilityTypeChange={setSelectedUtilityType}
                                                selectedValue={selectedUtilityType}
                                                FormInitialValues={FormInitialValues}
                                                formType={isEdit ? "Save" : "add"}
                                                ButtonDisable={isSaving}
                                                isDeleteSave={isDeleteSave}
                                                handleDelete={handleDelete}
                                                saveAndExit={saveAndExit}
                                                loading={isEdit ? isLoadingEdit : isLoadingAdd}
                                                compnayID={compnayID}
                                                companyAdd={companyAdd}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <CommonModal
                        open={modalfield}
                        close={() => setModalField(false)}
                        FormInitialValues={FormInitialValuesDiff}
                        form={modal1}
                        onFinish={fieldAdd}
                        fields={newFields}
                        title={"Add Field"}
                        loading={isLoadingAddField}
                    />
                    <CommonModal
                        open={modalprovider}
                        close={() => setModalProvider(false)}
                        form={modal2}
                        onFinish={providerAdd}
                        FormInitialValues={FormInitialValuesDiff}
                        fields={providerFields}
                        title={"Add Service Provider"}
                        loading={isLoadingAddProvider}
                    />
                    <Modal
                        title="Change Form Labels"
                        open={labelsModal}
                        onOk={handleLabelModal}
                        onCancel={handleLabelModal}
                        className="assign_model"
                        width={720}
                        footer={false}
                    >
                        <div className="modalBody">
                            <Form
                                name="labelForm"
                                className="modalForm"
                                layout="vertical"
                                onFinish={handleSubmitData}
                                form={labelForm}
                            >
                                <div className="row">
                                    {orderedFields.map((field, index) => (
                                        <div
                                            key={field.id}
                                            className="col-md-6"
                                            draggable={field.name !== 'provider_id' && field.name !== 'company_id'}
                                            onDragStart={() => {

                                                if (field.name !== 'provider_id' && field.name !== 'company_id') {
                                                    handleDragStart(index);
                                                }
                                            }}
                                            onDragOver={(e) => {
                                                e.preventDefault();
                                            }}
                                            onDrop={(e) => {
                                                e.preventDefault();

                                                if (field.name !== 'provider_id' && field.name !== 'company_id') {
                                                    handleDrop();
                                                }
                                            }}
                                            onDragEnter={() => {

                                                if (field.name !== 'provider_id' && field.name !== 'company_id') {
                                                    dragOverItem.current = index;
                                                }
                                            }}
                                            style={{
                                                cursor: field.name !== 'provider_id' && field.name !== 'company_id' ? 'move' : 'not-allowed',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            <Form.Item
                                                name={field.name}
                                                label={field.label}
                                                initialValue={field.label}
                                            >
                                                <Input
                                                    type="text"
                                                    placeholder="Enter label text here..."
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        const newFields = [...orderedFields];
                                                        newFields[index].label = e.target.value;
                                                        setOrderedFields(newFields);
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>
                                    ))}
                                </div>
                                <div className="col-md-12 text-center mt-4">
                                    <Button type="primary" className="authBtn" htmlType="submit">
                                        {isLoadingUpdate ? "Loading.." : "Save"}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default PropertyDetails;
