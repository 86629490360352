import { Button, Form, Input, message, Spin } from "antd";
import Title from "../../component/PageTitle";
import { useEffect, useState } from "react";
import FormItem from "antd/es/form/FormItem";
import { useUpdateLabelsMutation } from "../../slices/apiSlice";
import { useGetLabelsQuery, useGetUserDetailQuery } from "../../slices/getSlice";
import { useDispatch } from 'react-redux';
import { colorsData } from "../../slices/authSlice";
import { useOutletContext } from "react-router-dom";

const MenuLabel = () => {
    const [form] = Form.useForm();
    const [headingForm] = Form.useForm()
    const [colorForm] = Form.useForm();
    const { loading, setLoading } = useOutletContext();
    const [isHeadingSaving, setIsHeadingSaving] = useState(false)
    const [isSidebarSaving, setIsSidebarSaving] = useState(false)
    const [isColorSaving, setIsColorSaving] = useState(false)
    const [labelUpdate] = useUpdateLabelsMutation();
    const [primaryColor, setPrimaryColor] = useState(); // Default color
    const [secondaryColor, setSecondaryColor] = useState(); // Default color
    const dispatch = useDispatch();
    const {
        data: labelData = {}, // Default to an empty object to avoid errors
        error: labelsError,
        isLoading,
        refetch: refetchLabelData
    } = useGetLabelsQuery('menu');
    const {
        data: getUserDetail,
        refetch,
    } = useGetUserDetailQuery();

    const onFinish = async (value) => {
        const convertedData = {
            layout_data: {
                audit_log: value.audit_log,
                dashboard: value.dashboard,
                groups: value.groups,
                income: value.income,
                overall_performance: value.overall_performance,
                properties: value?.properties,
                sub_account: value.sub_account,
                transaction: value.transaction,
                recurring_transaction: value.recurring_transaction,
                expense_category: value.expense_category,
                market_analysis: value.market_analysis,
                report_analysis: value.report_analysis,
                expense: value.expense,
                setting: value.setting,

            }
        };
        setIsSidebarSaving(true);
        try {
            // settingUpdate({ data, id: getUserDetail?.data?.setting?.id });
            await labelUpdate({ label: 'menu', value: convertedData }).unwrap();
            refetchLabelData()
            // Show a success message
            message.success('Labels updated successfully!'); // Add this line
            refetch();
        }
        catch {
        } finally {
            setIsSidebarSaving(false);
            // dispatchEvent()
        }
    };

    const onSubmit = async (value) => {
        const convertedData = {
            layout_data: {

                properties: value?.properties,
                all_transactions: value.all_transactions,
                analysis: value.analysis,
                settings: value.setsettingsting,
            }
        };
        setIsHeadingSaving(true);
        try {
            await labelUpdate({ label: 'menu_headings', value: convertedData }).unwrap();
            refetchLabelData()

            message.success('Labels updated successfully!'); // Add this line
            refetch();
        }
        catch {
        } finally {
            setIsHeadingSaving(false);
            // dispatchEvent()
        }
    };

    useEffect(() => {
        if (getUserDetail?.data?.setting?.custom_data) {
            form.setFieldsValue(getUserDetail?.data?.setting?.custom_data?.menu)
            headingForm.setFieldsValue(getUserDetail?.data?.setting?.custom_data?.menu_headings)
        }
        setLoading(false)
    }, [getUserDetail])

    let LabelData = getUserDetail?.data?.setting?.custom_data?.menu;
    let menuHeading = getUserDetail?.data?.setting?.custom_data?.menu_headings;
    let colorDataValues = getUserDetail?.data?.setting?.custom_data?.colors;

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };

    const onSubmitColors = async () => {
        let pColor = primaryColor !== undefined ? primaryColor : colorDataValues.primary;
        let SColor = secondaryColor !== undefined ? secondaryColor : colorDataValues.secondary;

        const colorData = {
            layout_data: {
                primary: pColor,
                secondary: SColor,
            }
        };
        setIsColorSaving(true);
        try {
            await labelUpdate({ label: 'colors', value: colorData }).unwrap();
            refetch();
        }
        catch {

        } finally {
            setIsColorSaving(false);
            dispatch(
                colorsData({
                    primary: pColor,
                    secondary: SColor
                })
            );
        }
    }

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}

            <div className="customPadding">
                <div className="mainWrapper">
                    <div className="card titleCard mb-4">
                        <div className="card-body d-block">
                            <Title title="Labels" />
                            <p className="noteText">You can change the sidebar menu labels here.</p>
                        </div>
                    </div>
                    <div className="card tableCard mb-4">
                        <div className="card-header">
                            <h5 className="card-title mb-0 px-4">
                                Sidebar Labels
                            </h5>
                        </div>
                        <div className="card-body">
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                                className="labelsForms"
                            >
                                <div className="row">
                                    <div className="col-md-4">
                                        <FormItem
                                            name='dashboard'
                                            label={capitalizeFirstLetter(LabelData?.dashboard) || 'Dashboard'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-4">
                                        <FormItem
                                            name='properties'
                                            label={capitalizeFirstLetter(LabelData?.properties) || 'Properties'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-4">
                                        <FormItem
                                            name='groups'
                                            label={capitalizeFirstLetter(LabelData?.groups) || 'Groups'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-4">
                                        <FormItem
                                            name='income'
                                            label={capitalizeFirstLetter(LabelData?.income) || 'Income'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-4">
                                        <FormItem
                                            name='sub_account'
                                            label={capitalizeFirstLetter(LabelData?.sub_account) || 'Sub Account'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-4">
                                        <FormItem
                                            name='overall_performance'
                                            label={capitalizeFirstLetter(LabelData?.overall_performance) || 'Overall Performance'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-4">
                                        <FormItem
                                            name='recurring_transaction'
                                            label={capitalizeFirstLetter(LabelData?.recurring_transaction) || 'Recurring Transaction'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-4">
                                        <FormItem
                                            name='expense'
                                            label={capitalizeFirstLetter(LabelData?.expense) || 'Expense'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-4">
                                        <FormItem
                                            name='report_analysis'
                                            label={capitalizeFirstLetter(LabelData?.report_analysis) || 'Report Analysis'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-4">
                                        <FormItem
                                            name='market_analysis'
                                            label={capitalizeFirstLetter(LabelData?.market_analysis) || 'Market Analysis'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-4">
                                        <FormItem
                                            name='expense_category'
                                            label={capitalizeFirstLetter(LabelData?.expense_category) || 'Expense Category'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-4">
                                        <FormItem
                                            name='audit_log'
                                            label={capitalizeFirstLetter(LabelData?.audit_log) || 'Audit Log'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-4">
                                        <FormItem
                                            name='transaction'
                                            label={capitalizeFirstLetter(LabelData?.transaction) || 'Transaction'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-4">
                                        <FormItem
                                            name='setting'
                                            label={capitalizeFirstLetter(LabelData?.setting) || 'Setting'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-4">
                                        <FormItem
                                            name='recurring_transaction'
                                            label={capitalizeFirstLetter(LabelData?.recurring_transaction) || 'Recurring Transaction'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-12 text-center mt-3">
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className={"authBtn"}
                                            disabled={isSidebarSaving}
                                        >
                                            {isSidebarSaving ? "Loading.." : "Save"}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>

                    <div className="card tableCard mb-4">
                        <div className="card-header">
                            <h5 className="card-title mb-0 px-4">
                                Menu Heading Labels
                            </h5>
                        </div>
                        <div className="card-body">
                            <Form
                                form={headingForm}
                                layout="vertical"
                                onFinish={onSubmit}
                                className="labelsForms"
                            >
                                <div className="row">
                                    <div className="col-md-4">
                                        <FormItem
                                            name='properties'
                                            label={capitalizeFirstLetter(menuHeading?.properties) || 'Properties'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-4">
                                        <FormItem
                                            name='all_transactions'
                                            label={capitalizeFirstLetter(menuHeading?.all_transactions) || 'All Transactions'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-4">
                                        <FormItem
                                            name='settings'
                                            label={capitalizeFirstLetter(menuHeading?.settings) || 'Settings'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-4">
                                        <FormItem
                                            name='analysis'
                                            label={capitalizeFirstLetter(menuHeading?.analysis) || 'Analysis'}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || /^[A-Z]/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("First letter must be capital"));
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="form-control"
                                                type='text'
                                            />
                                        </FormItem>
                                    </div>

                                    <div className="col-md-12 text-center mt-3">
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className={"authBtn"}
                                            disabled={isHeadingSaving}
                                        >
                                            {isHeadingSaving ? "Loading.." : "Save"}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>

                    <div className="card tableCard mb-4 colorsCard">
                        <div className="card-header">
                            <h5 className="card-title mb-0 px-4">Theme Colors</h5>
                        </div>
                        <div className="card-body">
                            <Form
                                form={colorForm}
                                layout="vertical"
                                onFinish={onSubmitColors}
                                className="colorForms"
                            >
                                <div className="formWrapper">
                                    <label>Primary Color</label>
                                    <Form.Item name="primary_color">
                                        <div className="colorPickerContainer">
                                            <input
                                                type="color"
                                                value={primaryColor || colorDataValues?.primary}
                                                onChange={(e) => setPrimaryColor(e.target.value)}
                                                style={{ width: '180px', padding: '8px' }}
                                            />
                                        </div>
                                    </Form.Item>
                                </div>
                                <div className="formWrapper">
                                    <label>Secondary Color</label>
                                    <Form.Item name="secondary_color">
                                        <div className="colorPickerContainer">
                                            <input
                                                type="color"
                                                value={secondaryColor || colorDataValues?.secondary}
                                                onChange={(e) => setSecondaryColor(e.target.value)}
                                                style={{ width: '180px', padding: '8px' }}
                                            />
                                        </div>
                                    </Form.Item>
                                </div>
                                <hr />
                                <div className="text-center mt-3">
                                    <Button type="primary" htmlType="submit" size="medium" className={"authBtn"} disabled={isColorSaving}>
                                        {isColorSaving ? "Loading.." : "Save"}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MenuLabel;
