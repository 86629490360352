import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Modal, Select, Table, Tooltip, Input, Row, Col, Spin } from "antd";
import Title from "../../component/PageTitle";
import { IoAddCircleOutline } from "react-icons/io5";
import {
	useCategorizePlaidDataQuery,
	useGetCategoryQuery,
	useGetPropertiesQuery,
	useGetUtilityCategoryQuery,
} from "../../slices/getSlice";
import { DatePicker, Space } from "antd";
import { useAddRecurringDataMutation, useAddRecurringTransactionMutation } from "../../slices/apiSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { useOutletContext } from "react-router-dom";

function RecurringTransaction() {
	const [form] = Form.useForm();
	const { loading, setLoading } = useOutletContext();
	const { RangePicker } = DatePicker;
	const { data: categorizePlaidData, refetch, isLoading: isLoadingPlaid } = useCategorizePlaidDataQuery(true);
	const { data: getProperties } = useGetPropertiesQuery();
	const { data: getUtilityCategory } = useGetUtilityCategoryQuery();
	const [addRecurringData, { isLoading: isaddLoading }] = useAddRecurringDataMutation();
	const [addRecurringTransaction] = useAddRecurringTransactionMutation();
	const { data: getCategory } = useGetCategoryQuery("expense");
	const today = new Date();
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [selectedRowsData, setSelectedRowsData] = useState([])
	const [properties, setProperties] = useState([]);
	const [category, setCategory] = useState();
	const [linkData, setLinkData] = useState();
	const [formData, setFormData] = useState([]);
	const [utilityCategory, setUtilityCategory] = useState();
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const colors = useSelector((state) => state?.persistedReducer?.colors);
	const [currentPage, setCurrentPage] = useState(1);
	const pageSize = 5;

	const start_date = startDate
		? new Date(startDate)
		: new Date(today.getFullYear(), today.getMonth(), 1);
	const end_date = endDate
		? new Date(endDate)
		: new Date(today.getFullYear(), today.getMonth() + 1, 0);

	const dataSource = categorizePlaidData?.data
		?.map((transaction) => {

			const parsedData = JSON.parse(transaction.json_data);

			const filteredItems = [parsedData].filter((item) => {
				const itemDate = new Date(item.last_date);
				return itemDate >= start_date && itemDate <= end_date;
			});

			if (filteredItems.length > 0) {
				return {
					key: filteredItems[0]?.transaction_id,
					amount: filteredItems[0]?.last_amount?.amount,
					date: filteredItems[0]?.last_date,
					name: filteredItems[0]?.description,
					transaction_id: transaction?.id,
					stream_id: filteredItems[0]?.stream_id,
					payment_date: filteredItems[0]?.last_date,
					next_payment_date: filteredItems[0]?.predicted_next_date,
					start_date: filteredItems[0]?.first_date,
					frequency: filteredItems[0]?.frequency,
					property_id: null,
					provider_type_id: null,
					category_id: null,
				};
			}

			return null;
		})
		.filter((item) => item !== null);

	const DateFormatter = (date) => {
		const dateObj = new Date(date);
		const monthNames = [
			"January", "February", "March", "April", "May", "June",
			"July", "August", "September", "October", "November", "December"
		];
		const day = dateObj.getDate();
		const month = monthNames[dateObj.getMonth()];
		const year = dateObj.getFullYear();
		return `${month} ${day} ${year}`;
	};

	useEffect(() => {
		if (getProperties && getProperties?.data) {
			const transformedOptions = getProperties?.data?.map((item) => ({
				label: item.name_of_property,
				value: item.id,
			}));
			setLoading(false)
			setProperties(transformedOptions);
		}
		if (getUtilityCategory && getUtilityCategory?.data) {
			const transformedOptions = getUtilityCategory?.data?.map((item) => ({
				label: item.name,
				value: item.id,
			}));
			setUtilityCategory(transformedOptions);
		}
		if (getCategory && getCategory.data) {
			const transformedOptions = getCategory.data.map((item) => ({
				label: item.name,
				value: item.id,
			}));
			setCategory(transformedOptions);
		}
	}, [getProperties, getUtilityCategory, getCategory]);
	useEffect(() => {
		if (categorizePlaidData) {
			form.setFieldsValue(linkData)
		}
	}, [linkData])

	const columns = [
		{
			title: "Transaction Name",
			dataIndex: "transactionName",
			render: (text, record) => (
				<div className="category-data-container">
					<p className="category-data-text">{record.name}</p>
				</div>
			),
		},
		{
			title: "Amount",
			dataIndex: "amount",
			render: (text, record) =>
				record.amount ? (
					<div className="amount-data-container">
						<p className="amount-data-text">${record.amount?.toFixed(2)}</p>
					</div>
				) : (
					""
				),
		},
		{
			title: "Transaction Date",
			dataIndex: "transactionDate",
			render: (text, record) =>
				record.date ? (
					<div className="date-data-container">
						<p className="date-data-text">{DateFormatter(record.date)}</p>
					</div>
				) : (
					""
				),
		},
		{
			title: "Action",
			dataIndex: "action",
			render: (_, record) => {
				return (
					<div className="action-container">
						<Tooltip>
							<button className="btn btnAdd me-2">
								<IoAddCircleOutline onClick={() => { setIsModalOpen(true); setLinkData(record) }} />
							</button>
						</Tooltip>
					</div>
				);
			},
		},
	];

	const handleSelect = (record, selected) => {

		if (selected) {
			setSelectedRowKeys((prev) => [...prev, record.key]);
			setSelectedRowsData((prev) => [...prev, record]);
		} else {
			setSelectedRowKeys((prev) => prev.filter((key) => key !== record.key));
			setSelectedRowsData((prev) => prev.filter((row) => row.key !== record.key));
		}
	};

	const toggleSelectAll = (e) => {
		if (e.target.checked) {
			setSelectedRowKeys(dataSource.map((r) => r.key));
			setSelectedRowsData(dataSource);
		} else {
			setSelectedRowKeys([]);
			setSelectedRowsData([]);
		}
	};

	const headerCheckbox = (
		<Checkbox
			checked={selectedRowKeys.length === dataSource?.length}
			indeterminate={
				selectedRowKeys.length > 0 &&
				selectedRowKeys.length < dataSource?.length
			}
			onChange={toggleSelectAll}
			className="custom-check"
		/>
	);

	const rowSelection = {
		selectedRowKeys,
		onChange: setSelectedRowKeys,
		columnTitle: headerCheckbox,
		onSelect: handleSelect,
		onSelectAll: (selected, selectedRows) => {
			if (selected) {
				setSelectedRowKeys(selectedRows.map((row) => row.key));
				setSelectedRowsData(selectedRows);
			} else {
				setSelectedRowKeys([]);
				setSelectedRowsData([]);
			}
		},
	};

	const handleFinish = async (value) => {
		let storedFormData = [...formData];
		if (linkData) {
			let values = {
				property_id: value.properties.value,
				provider_type_id: value.utilityCategory.value,
				category_id: value?.category_id?.value,
				amount: linkData?.amount,
				start_date: linkData?.start_date,
				transaction_id: linkData?.transaction_id,
				stream_id: linkData?.stream_id,
				payment_date: linkData?.payment_date,
				next_payment_date: linkData?.next_payment_date,
				frequency: linkData?.frequency
			};
			if (values) {

				storedFormData = [values];
				setFormData(storedFormData);
			}
		} else {
			storedFormData = selectedRowsData.map((item) => ({
				...item,
				stream_id: linkData?.stream_id,
				payment_date: linkData?.payment_date,
				next_payment_date: linkData?.next_payment_date,
				property_id: value.properties.value,
				provider_type_id: value.utilityCategory.value,
				// category_id: value.category_id.value,
				frequency: linkData?.frequency
			}));
		}
		const response = await addRecurringData({ providers: storedFormData });
		if (response.data) {
			toast.success("Successfully added");
			refetch()
			form.resetFields();
			setIsModalOpen(false);
		} else {
			toast.error(`${response?.error?.data?.message}`);
		}
	};
	const getTransactions = async () => {
		try {
			const response = await addRecurringTransaction();
			if (response?.data?.data?.length === 0) {
				swal({
					title: "Alert",
					text: `${response.data.message}`,
					icon: "warning",
					dangerMode: true,
				})
			}
			refetch()
		} catch (error) {
			console.log(error, 'error')
		}
	};

	return (
		<>
			{loading && (
				<div className="commanLoaderWrapper">
					<Spin size="large" />
					<h4>Setting things up..</h4>
				</div>
			)}
			<div className="customPadding">
				<div className="mainWrapper">
					<div className="card titleCard mb-4">
						<div className="card-body">
							<Title title="Recurring Transactions" id="recurring_transaction" />
							<div className="rightButtons">
								<Space direction="vertical" size={10} className="me-3">
									<RangePicker
										format="MM/DD/YYYY"
										onChange={(dateString) => {
											if (dateString) {
												setStartDate(dateString[0] || null);
												setEndDate(dateString[1] || null);
											} else {
												setStartDate(null);
												setEndDate(null);
											}
										}}
									/>
								</Space>
								{dataSource?.length ? (
									selectedRowKeys?.length > 1 ||
										selectedRowKeys?.length === dataSource?.length ? (
										<Button
											type="primary"
											style={{
												backgroundColor: colors?.primary || "#F47A3A", marginRight: '1rem'
											}}
											onClick={() => setIsModalOpen(true)}
										>
											{"Add your transaction"}
										</Button>
									) : (
										""
									)
								) : (
									""
								)}
								<Button
									type="primary"
									style={{
										backgroundColor: colors?.primary || "#F47A3A",
									}}
									onClick={() => getTransactions()}
								>
									{"Add transaction"}
								</Button>
							</div>
						</div>
					</div>

					<div className="card tableCard">
						<div className="card-body">
							<div className="table-responsives">
								{isLoadingPlaid ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
									<Spin />
								</div> :
									<Table
										rowKey={(record) => record.key}
										rowSelection={rowSelection}
										columns={columns}
										dataSource={dataSource ? dataSource : ""}
										scroll={{ x: '100%' }}
										pagination={{
											pageSize,
											current: currentPage,
											onChange: page => setCurrentPage(page),
										}}
									/>}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal
				className="deleteModal addTransCustomModal"
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				footer={false}
				title={
					<>
						Add your transaction
						<div style={{ marginTop: '8px', fontWeight: 700, width: 'fit-content', fontSize: '1rem' }}>
							${linkData?.amount?.toFixed(2)}
						</div>
					</>
				}
			>
				<Form
					form={form}
					layout="vertical"
					onFinish={handleFinish}
				>
					<div className="textRow me-2">

						<Form.Item
							label="Properties Mode"
							className="firstFormItem"
							name="properties"
							rules={[{ required: true, message: "Please select at least one property." }]}
						>
							<Select
								className="formControl select-custom mb-3"
								style={{ width: "100%" }}
								placeholder="Properties Mode"
								labelInValue
								options={properties}
							/>
						</Form.Item>

						<Form.Item
							label="Provider Mode"
							name="utilityCategory"
							rules={[{ required: true, message: "Please select at least one utility category." }]}
						>
							<Select
								className="formControl select-custom"
								style={{ width: "100%" }}
								placeholder="Provider Mode"
								labelInValue
								options={utilityCategory}
							/>
						</Form.Item>

						<Form.Item
							label="Amount"
							name="amount"

						>
							<Input
								className="form-control"
								style={{ width: "100%" }}
								placeholder="Amount"
								disabled
							/>
						</Form.Item>
						<Form.Item
							label="Start Date"
							name="start_date"
						>
							<Input
								className="form-control"
								style={{ width: "100%" }}
								placeholder="Start Date"
								type="date"
								disabled
							/>
						</Form.Item>
						<Form.Item
							label="Frequency"
							name="frequency"
						>
							<Select
								className="formControl select-custom"
								style={{ width: "100%" }}
								placeholder="Frequency"
								disabled
							/>
						</Form.Item>
						<Form.Item
							label="Payment Date"
							name="payment_date"

						>
							<Input
								className="form-control"
								style={{ width: "100%" }}
								placeholder="Payment Date"
								type="date"
								disabled
							/>
						</Form.Item>
						<Form.Item
							label="Next Payment Date"
							name="next_payment_date"

						>
							<Input
								className="form-control"
								style={{ width: "100%" }}
								placeholder="Next Payment Date"
								type="date"
								disabled
							/>
						</Form.Item>
					</div>

					<Form.Item>
						<Button type="primary" htmlType="submit" className="authBtn">
							{isaddLoading ? 'Loading..' : 'Submit'}
						</Button>
					</Form.Item>
				</Form>
			</Modal>

		</>
	);
}

export default RecurringTransaction;
