import React, { useEffect, useState } from "react";
import { Pagination, Table, Spin, Select, Empty } from "antd";
import Title from "../../component/PageTitle";
import { useGetAllExpenseQuery, useGetUtilityCategoryQuery } from "../../slices/getSlice";
import { useSelector } from "react-redux";
import { useGetPropertiesQuery } from "../../slices/getSlice";
import { useOutletContext } from "react-router-dom";
import moment from "moment";

const Expesne = () => {
    const currencyGlobal = useSelector((state) => state?.persistedReducer?.currency?.currency_symbol)
    const currency = currencyGlobal ? currencyGlobal : '';
    const { loading, setLoading } = useOutletContext();
    const [selectedProperty, setSelectedProperty] = useState([])
    const [selectedUtility, setSelectedUtility] = useState([])
    const pageSize = 5
    const { data: getAllExpense, isLoading, isError, refetch } = useGetAllExpenseQuery({
        property_id: selectedProperty,
        provider_type_id: selectedUtility,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [properties, setProperties] = useState()

    const [utilityCategory, setUtilityCategory] = useState()
    const {
        data: getProperties,
    } = useGetPropertiesQuery();
    const {
        data: getUtilityCategory = [],
    } = useGetUtilityCategoryQuery();
    const handlePropertyChange = (value) => {
        setSelectedProperty(value);

    };
    const handleUtilityChange = (value) => {
        setSelectedUtility(value)
    };
    useEffect(() => {
        refetch();
    }, [selectedProperty, selectedUtility, refetch]);

    useEffect(() => {
        if (getProperties && getProperties?.data) {
            const transformedOptions = getProperties?.data?.map((item) => ({
                label: item.name_of_property,
                value: item.id,
            }));
            setLoading(false)
            setProperties(transformedOptions);
        }
        if (getUtilityCategory && getUtilityCategory?.data) {
            const transformedOptions = getUtilityCategory?.data?.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setUtilityCategory(transformedOptions);
        }
    }, [getProperties, getUtilityCategory]);

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };

    const totalItems = getAllExpense ? getAllExpense?.data?.length : 0;
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, totalItems);
    const displayedItems = getAllExpense?.data?.slice(startIndex, endIndex);
    const Columns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            render: (_, record, index) =>
                ((currentPage - 1) * pageSize + index + 1)

        },
        {
            title: "Property Name",
            render: (_, record) => {

                return record?.property?.name_of_property
            },
        },
        {
            title: "Provider Type",
            render: (_, record) => {
                return record?.providerType?.name
            },
        },
        {
            title: "Company Name",
            dataIndex: "company",
            render: (_, record) => {
                return record?.company?.company_name
            },
        },
        {
            title: "Amount",
            dataIndex: "amount",
            render: (record) => `${currency} ${record || 0}`
        },
    ];

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}

<div className="customPadding">
            <div className="mainWrapper">
                <div className="card titleCard mb-4">
                    <div className="card-body">
                        <Title title="All Expense" id="expense" />
                        <div className="filterSection">
                            <Select
                                mode="multiple"
                                value={selectedProperty}
                                placeholder="Filter by Properties"
                                style={{
                                    width: 200,
                                    marginRight: "1rem",
                                }}
                                onChange={(value) => handlePropertyChange(value)}
                                options={properties}
                                className="mobWidth"
                            />
                            <Select
                                mode="multiple"
                                value={selectedUtility}
                                placeholder="Filter by Utility Category"
                                style={{
                                    width: 200,
                                    marginRight: "1rem",
                                }}
                                onChange={(value) => handleUtilityChange(value)}
                                options={utilityCategory}
                                className="mobWidth"
                            />


                        </div>
                    </div>
                </div>

                <div className="card tableCard">
                    <div className="card-body">
                        {isLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <Spin />
                            </div>
                        ) : isError && displayedItems?.length < 0 ? (
                            <h4>An error occurred</h4>
                        ) : (
                            <div className="table-responsives">

                                {displayedItems?.length > 0 ? (
                                    <>
                                        <Table
                                            rowKey={record => record.id}
                                            rowClassName={() => "editable-row"}
                                            bordered
                                            dataSource={displayedItems}
                                            columns={Columns}
                                            pagination={false}
                                            scroll={{ x: 'auto' }}
                                        />
                                        <Pagination
                                            className="mt-4"
                                            current={currentPage}
                                            pageSize={pageSize}
                                            total={totalItems}
                                            onChange={handleChangePage}
                                        />
                                    </>
                                ) : (
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data found" />
                                )}

                            </div>
                        )}
                    </div>
                </div>

            </div>
            </div>
        </>
    );
};
export default Expesne;