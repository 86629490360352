import React from "react";
import { Form, Row, Col, Input, Button } from "antd";
import { CiLock } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import images from "../../constant/images";
import { useResetPasswordSubAccountMutation } from "../../slices/loginRegisterSlice";

const SubAccountReset = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token");
    const { Logo, SignInImage } = images;

    const fields = [
        {
            component: "input",
            type: "password",
            label: "Password",
            name: "password",
            placeholder: "Enter password",
            prefix: <CiLock style={{ color: "#F47939", fontSize: "25px" }} />,
            rules: [
                {
                    required: true,
                    message: "Please input your password",
                },
                {
                    pattern:
                        /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                    message:
                        "Password must be minimum 8 characters, include at least one uppercase letter, one lowercase letter, one number, and one special character.",
                },
            ],
        },
        {
            component: "input",
            type: "password",
            dependencies: "password",
            label: "Confirm Password",
            name: "password_confirmation",
            placeholder: "Enter Confirm password",
            prefix: <CiLock style={{ color: "#F47939", fontSize: "25px" }} />,
            rules: [
                {
                    required: true,
                    message: "Please input your password",
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(
                            new Error("The confirm passwords do not match!")
                        );
                    },
                }),
            ],
        },
    ];
    const [subAccountResetPassword, { isLoading, error }] = useResetPasswordSubAccountMutation();
    const onFinish = async (value) => {
        let data = {
            token: token,
            ...value,
        };
        try {
            const response = await subAccountResetPassword(data);
            if (response.data) {
                navigate("/login");
            } else {
                toast.error(`${response?.error?.data?.message}`);
            }
        } catch (error) {
            console.error(error)
        }
    };
    return (
        <div className="authWrapper">
            <div className="authInner">
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        <div className="col-md-6 whiteBg">
                            <div className="formWrapper">
                                <div className="logoWrap">
                                    <Logo />
                                </div>
                                <div className="titleWrap">
                                    <h2 className="authTitle">Set Your Password</h2>
                                </div>
                                <div className="formWrap">
                                    <Form
                                        form={form}
                                        layout="vertical"
                                        onFinish={onFinish}
                                    >
                                        {fields?.map((item, i) => (
                                            <div>
                                                <Form.Item
                                                    label={item?.label}
                                                    name={item?.name}
                                                    rules={item?.rules}
                                                >
                                                    <Input.Password
                                                        placeholder={item?.placeholder}
                                                        defaultValue={item?.defaultValue}
                                                        className="form-control"
                                                        prefix={item?.prefix}
                                                        disabled={item?.disabled || ""}
                                                        type={item?.type}
                                                    />
                                                </Form.Item>
                                            </div>
                                        ))}
                                        <Row className="formButtons">
                                            <Col md={24} className="text-center mt-3">
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    className="authBtn"
                                                    disabled={isLoading ? isLoading : false}
                                                >
                                                    {isLoading ? "Loading..." : 'Save'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mobHide">
                            <div className="imgWrapper">
                                <SignInImage />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SubAccountReset;