import React, { useState } from "react";
import {Button, Form, Input} from "antd";
import DynamicForm from "../../component/Form";
import { CiLock, CiMail } from "react-icons/ci";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import images from "../../constant/images";
import { useUserRegisterMutation } from "../../slices/loginRegisterSlice";
import {LockOutlined, MailOutlined} from "@ant-design/icons";

const SignUp = () => {
const [errorMessage, setErrorMessage] = useState("");
const [successMessage, setSuccessMessage] = useState("");
const [signup] = Form.useForm();
const { Logo, SignUpImage } = images;
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const [userRegister, { isLoading}] = useUserRegisterMutation();

const onFinish = async (value) => {
  try {
    const response = await userRegister(value);
    handleResponse(response);
  } catch (error) {
    handleError();
  }
};

const handleResponse = (response) => {
  signup.resetFields();

  if (response.error) {
    setErrorMessage(response.error.data.message);
    resetMessage(setErrorMessage);
  } else {
    setSuccessMessage(response.data.message);
    resetMessage(setSuccessMessage);
  }
};

const handleError = () => {
  toast.error("Incorrect password and email");
};

const resetMessage = (setMessage) => {
  setTimeout(() => {
    setMessage("");
  }, 5000);
};

return (
  <div className="authWrapper">
    <div className="authInner">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-6 whiteBg">
            <div className="formWrapper">
              <div className="logoWrap">
                <Logo />
              </div>
              <div className="titleWrap">
                <h2 className="authTitle">Create your Account</h2>
                <p className="authSubTitle">Unlock all features</p>
              </div>
              <div className="formWrap">
                {errorMessage && (
                  <div style={{ color: "red" }}>{errorMessage}</div>
                )}
                {successMessage && (
                  <div style={{ color: "#0FFF50" }}>{successMessage}</div>
                )}
                <Form
                    form={signup}
                    name="signup"
                    onFinish={onFinish}
                    initialValues={{
                      rememberMe: false,
                    }}
                    layout="vertical"
                >
                  <Form.Item
                      name="email"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (!value) {
                              return Promise.reject(new Error("Please input your email"));
                            }
                            const trimmedValue = value.trim();
                            if (trimmedValue === "") {
                              return Promise.reject(new Error("Spaces are not allowed"));
                            }
                            if (!emailRegex.test(trimmedValue)) {
                              return Promise.reject(new Error("Please enter a valid email address"));
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                  >
                    <Input placeholder="Enter Email" className="form-control" prefix={<CiLock style={{ color: "#F47939", fontSize: "25px" }} />} />
                  </Form.Item>

                  <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password",
                        },
                        {
                          pattern:
                              /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                          message:
                              "Password must be minimum 8 characters, include at least one uppercase letter, one lowercase letter, one number, and one special character.",
                        },
                      ]}
                  >
                    <Input.Password placeholder="Enter Password" className="form-control" prefix={<LockOutlined style={{ color: 'rgb(244, 121, 57)', fontSize: '22px' }} />} />
                  </Form.Item>
                  <Form.Item
                      name="password_confirmation"
                      dependencies={['password']}
                      rules={[
                        {
                          required: true,
                          message: 'Please input your confirm password',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('The confirm passwords do not match!'));
                          },
                        })
                      ]}
                  >
                    <Input.Password placeholder="Enter Confirm password" className="form-control" prefix={<CiLock style={{ color: "#F47939", fontSize: "25px" }} />} />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="authBtn" loading={isLoading}>
                      Sign Up
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              <div className="bottomTextRow">
                <span>You have account?</span>
                <span className="text-wrapper-10">&nbsp;</span>
                <Link to="/login" className="signUpLink">
                  Sign In
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 mobHide">
            <div className="imgWrapper">
              <SignUpImage />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
};

export default SignUp;
