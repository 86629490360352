import React, { useState, useEffect } from "react";
import { Spin, Drawer, Menu } from 'antd';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logout, resetUserState } from "../../slices/authSlice";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useGetUserDetailQuery } from "../../slices/getSlice";

const CustomDrawer = ({ isOpen, onClose }) => {
    const location = useLocation()
    const {
        data: getUserDetail,
        isLoading: loading,
        refetch,
    } = useGetUserDetailQuery();
    const [selectedKey, setSelectedKey] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const status = useSelector((state) => state.persistedReducer.status);
    const userData = useSelector((state) => state.persistedReducer);
    const parent_id = userData?.user?.user?.parent_id;
    const [menuLabels, setMenuLabels] = useState({});

    const handleLogout = () => {
        dispatch(resetUserState());
        dispatch(logout());
        localStorage.clear();
        navigate("/login");
        toast.success('Logout successfully');
    };

    useEffect(() => {
        const path = location.pathname;
        let key = path;

        if (path === '/view_property' || path === '/add_property' || path === '/property_details') {
            key = '/properties';
        } else if (path === '/add_income') {
            key = '/income';
        }

        setSelectedKey(key);

    }, [location?.pathname, getUserDetail]);

    useEffect(() => {
        let mydata = getUserDetail?.data?.setting?.custom_data;
        if (typeof mydata != "undefined") {
            setMenuLabels(mydata?.menu)
        }

    }, [getUserDetail]);

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };

    let SecondaryColor = getUserDetail?.data?.setting?.custom_data?.colors?.secondary;

    const items = [
        {
            key: '/dashboard',
            icon: <i className="fa fa-house menu-icon"></i>,
            disabled: !status,
            path: '/dashboard',
            label: status ? <Link className="opacity" to={'/dashboard'}>{capitalizeFirstLetter(menuLabels?.dashboard) || 'Dashboard'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.dashboard) || 'Dashboard'}</span>,
            className: 'custom-menu-item',
        },
        {
            key: '/properties',
            icon: <i className="fa-solid fa-building menu-icon"></i>,
            path: '/properties',
            disabled: !status,
            label: status ? <Link to={'/properties'}>{capitalizeFirstLetter(menuLabels?.properties) || 'Properties'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.properties) || 'Properties'}</span>,
            className: 'custom-menu-item',
        },
        {
            key: '/manage_property',
            icon: <i className="fa-solid fa-layer-group menu-icon"></i>,
            path: '/manage_property',
            hidden: parent_id ? true : false,
            disabled: !status,
            label: status ? <Link to={'/manage_property'}>{capitalizeFirstLetter(menuLabels?.groups) || 'Groups'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.groups) || 'Groups'}</span>,
            className: 'custom-menu-item',
        },
        {
            key: '/expense',
            icon: <i className="fa-solid fa-dollar-sign menu-icon"></i>,
            path: '/expense',
            disabled: !status,
            label: status ? <Link to={'/expense'}>{capitalizeFirstLetter(menuLabels?.expense) || 'Expense'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.income) || 'Expense'}</span>,
            className: 'custom-menu-item',
        },
        {
            key: '/income',
            icon: <i className="fa-solid fa-hand-holding-dollar menu-icon"></i>,
            path: '/income',
            disabled: !status,
            label: status ? <Link to={'/income'}>{capitalizeFirstLetter(menuLabels?.income) || 'Income'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.income) || 'Income'}</span>,
            className: 'custom-menu-item',
        },
        {
            key: '/sub_account',
            icon: <i className="fa-solid fa-users menu-icon"></i>,
            path: '/sub_account',
            hidden: parent_id ? true : false,
            disabled: !status,
            label: status ? <Link to={'/sub_account'}>{capitalizeFirstLetter(menuLabels?.sub_account) || 'Sub Accounts'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.sub_account) || 'Sub Accounts'}</span>,
            className: 'custom-menu-item',
        },
        {
            key: '/report',
            icon: <i className="fa-solid fa-chart-simple menu-icon"></i>,
            path: '/report',
            hidden: parent_id ? true : false,
            disabled: !status,
            label: status ? <Link to={'/report'}>{capitalizeFirstLetter(menuLabels?.overall_performance) || 'Overall Performance'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.overall_performance) || 'Overall Performance'}</span>,
            className: 'custom-menu-item reportBtn',
        },
        {
            key: '/expense-category',
            icon: <i className="fa-solid fa-filter-circle-dollar menu-icon"></i>,
            path: '/expense-category',
            hidden: parent_id ? true : false,
            disabled: !status,
            label: status ? <Link to={'/expense-category'}>{capitalizeFirstLetter(menuLabels?.expense_category) || 'Expense Category'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.expense_category) || 'Expense Category'}</span>,
            className: 'custom-menu-item reportBtn',
        },
        {
            key: '/market-analysis',
            icon: <i className="fa-solid fa-chart-pie menu-icon"></i>,
            path: '/market-analysis',
            hidden: parent_id ? true : false,
            disabled: !status,
            label: status ? <Link to={'/market-analysis'}>{capitalizeFirstLetter(menuLabels?.market_analysis) || 'Market Analysis'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.market_analysis) || 'Market Analysis'}</span>,
            className: 'custom-menu-item reportBtn',
        },
        {
            key: '/report-analysis',
            icon: <i className="fa-solid fa-file-lines menu-icon"></i>,
            path: '/report-analysis',
            hidden: parent_id ? true : false,
            disabled: !status,
            label: status ? <Link to={'/report-analysis'}>{capitalizeFirstLetter(menuLabels?.report_analysis) || 'Report Analysis'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.report_analysis) || 'Report Analysis'}</span>,
            className: 'custom-menu-item reportBtn',
        },
        {
            key: '/all-transcation',
            icon: <i className="fa-solid fa-money-bill-transfer menu-icon"></i>,
            path: '/all-transcation',
            hidden: parent_id ? true : false,
            disabled: !status,
            label: status ? <Link to={'/all-transcation'}>{capitalizeFirstLetter(menuLabels?.transaction) || 'Transcation'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.transaction) || 'Transcation'}</span>,
            className: 'custom-menu-item reportBtn',
        },
        {
            key: '/setting',
            icon: <i className="fa-solid fa-gear menu-icon"></i>,
            label: status ? <Link to={'/setting'}>{capitalizeFirstLetter(menuLabels?.setting) || 'Setting'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.setting) || 'Setting'}</span>,
            path: '/setting',
            hidden: parent_id ? true : false,
            disabled: !status,
            className: 'custom-menu-item reportBtn',
        },
        {
            key: '/recurring_transcation',
            icon: <i className="fa-solid fa-arrow-right-arrow-left menu-icon"></i>,
            label: status ? <Link to={'/recurring_transcation'}>{capitalizeFirstLetter(menuLabels?.recurring_transaction) || 'Recurring Transaction'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.recurring_transaction) || 'Setting'}</span>,
            path: '/recurring_transcation',
            hidden: parent_id ? true : false,
            disabled: !status,
            className: 'custom-menu-item reportBtn',
        },
        {
            key: 9,
            icon: <i className="fa-solid fa-right-from-bracket menu-icon"></i>,
            label: 'Logout',
            style: { marginTop: '44px' },
            className: 'custom-menu-item bottem-menu-item',
            onClick: handleLogout
        },
    ]

    return (
        <>
            <Drawer onClose={onClose} open={isOpen} placement="left" className="customDrawer" style={{ background: SecondaryColor || '#3A54A5' }}>
                {
                    loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Spin />
                        </div>
                    ) : (
                        <Menu mode="inline" style={{ background: SecondaryColor || '#3A54A5', }} items={items} selectedKeys={[selectedKey]} onClick={onClose} className="mobileDrawer" />
                    )
                }
                <div className="versionWrapper">
                    <h3>V1.3</h3>
                </div>
            </Drawer>
        </>
    );
};
export default CustomDrawer;