import React, { useEffect, useState } from "react";
import { Button, Modal, Table, Tooltip, Pagination, Spin, Empty, Input } from "antd";
import { IoIosAddCircleOutline } from "react-icons/io";
import Title from "../../component/PageTitle";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { LuEye } from "react-icons/lu";
import { FaEdit } from "react-icons/fa";
import { HiOutlineTrash } from "react-icons/hi2";
import { useGetPropertiesQuery } from "../../slices/getSlice";
import { useDeletePropertyMutation } from "../../slices/apiSlice";
import { RxCrossCircled } from "react-icons/rx";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const AllProperties = () => {
    const pageSize = 5
    const {
        data: getProperties,
        isLoading: isPropertiesLoading,
        isError: isPropertiesError,
        error: propertyError,
        refetch
    } = useGetPropertiesQuery();
    const userData = useSelector((state) => state.persistedReducer);
    const parent_id = userData?.user?.user?.parent_id;
    const [deleteproperty, { }] = useDeletePropertyMutation()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRecord, setSelectedRecord] = useState('');
    const { loading, setLoading } = useOutletContext();
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
        if (getProperties) {
            setLoading(false)
        }

        refetch()
    }, [getProperties]);

    const handleClick = (record) => {
        navigate("/view_property", {
            state: { propertyData: record },
        });
    };

    const handleOk = async () => {
        const res = await deleteproperty(selectedRecord);
        if (res?.data?.message) {
            refetch();
            setIsModalOpen(false);
            toast.success(res?.data?.message)
        }
        else {
            toast.error("Not able to delete")
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedRecord('');
    };
    const handleChangePage = (page) => {
        setCurrentPage(page);

    };
    const dataSource = getProperties?.data?.map((property) => ({

        id: property.id,
        address: property.address,
        name_of_property: property.name_of_property,
        property_type_id: property.property_type_name.provider_name,
        property_typeId: property.property_type_name.id,
        country_id: property.country,
        state_id: property.state,
        city_id: property.city,
        country: property.country_id,
        state: property.state_id,
        city: property.city_id,
        zip_code: property.zip_code,
        manager_properties: property.manager_properties,
        manager_properties_users: property.manager_properties_users,
        ownership_type_name: property.ownership_type_name,
        manager_properties_users: property.manager_properties_users,
        property_type_name: property.property_type_name

    })) || [];

    const totalItems = dataSource ? dataSource.length : 0;
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, totalItems);
    const displayedItems = dataSource.slice(startIndex, endIndex);
    const Columns = [

        {
            title: "S.No.",
            dataIndex: "serial",
            render: (_, record, index) =>
                ((currentPage - 1) * pageSize + index + 1)

        },

        {
            title: "Property Name",
            dataIndex: "name_of_property",
            render: (_, record, index) => {
                return (
                    <>
                        <h6 className="mb-1">{record?.name_of_property}</h6>
                        <small className="textSecondary">{record?.property_type_name?.provider_name}</small>
                    </>
                )
            }
        },

        {
            title: "Property Managers",
            dataIndex: "managers",
            render: (_, record, index) => {
                return (
                    <>
                        {record?.manager_properties_users?.map((user, index) => (
                            <div key={index}>
                                <h6 className="mb-1">{user.user_name} - <small className="textSecondary">{user.ownership_type_name}</small></h6>
                            </div>
                        ))}
                    </>
                )
            }
        },
        {
            title: "Action",
            dataIndex: "id",
            render: (_, record) => {
                return (
                    <div className="action-container">
                        <Tooltip title="View and Manage">
                            <button className="btn btnView me-2" onClick={() => handleClick(record)}>
                                <LuEye onClick={() => handleClick(record)} />
                            </button>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <button className="btn btnEdit me-2" onClick={() => {

                                navigate("/add_property", { state: record })
                            }}>
                                <FaEdit />
                            </button>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <button className="btn btnDelete me-2" onClick={() => {
                                setSelectedRecord(record.id);
                                setIsModalOpen(true);
                            }}>
                                <HiOutlineTrash />
                            </button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];


    useEffect(() => {
        if (!searchText) {
            setFilteredItems(displayedItems); // Reset to original data when searchText is cleared
        } else {
            const lowerCaseSearchText = searchText.toLowerCase();
            setFilteredItems(
                displayedItems.filter((item) =>
                    Object.values(item).some((val) =>
                        val?.toString()?.toLowerCase().includes(lowerCaseSearchText)
                    )
                )
            );
        }
    }, [searchText]);

    const handleSearchChange = (e) => {
        setSearchText(e?.target?.value); // Update search text on every keystroke
    };


    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
      <div className="customPadding">
            <div className="mainWrapper">
                {/* Button and Title Section */}

                <div className="card titleCard mb-4">
                    <div className="card-body">
                        <Title title="All Properties" id='properties' />
                        {parent_id ? '' : <Button
                            type="primary"
                            style={{
                                backgroundColor: colors?.primary || "#F47A3A",
                            }}
                        >
                            <Link to={"/add_property"} className="link">
                                {"Add new property"}{" "}
                                <IoIosAddCircleOutline style={{ marginLeft: "10px" }} />
                            </Link>
                        </Button>}
                    </div>
                </div>


                <div className="card tableCard">
                    <div className="card-body">
                        {isPropertiesLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <Spin />
                            </div>
                        ) : isPropertiesError || displayedItems.length < 0 ? (
                            <h4>No data found</h4>
                        ) : (
                            <div className="tableResponsives">
                                <Input.Search
                                    placeholder="Search properties"
                                    allowClear
                                    value={searchText}
                                    onChange={handleSearchChange}
                                    onSearch={(value) => setSearchText(value)}
                                    className="mb-4 tableSearchInput"
                                    style={{ width: '20%' }}
                                />
                                {filteredItems.length > 0 || displayedItems.length > 0 ? (
                                    <>
                                        <Table
                                            className="tableNew"
                                            rowKey={(record) => record.id}
                                            rowClassName={() => "editable-row"}
                                            bordered
                                            pagination={false}
                                            dataSource={searchText ? filteredItems : displayedItems}
                                            columns={Columns}
                                            scroll={{ x: "100%" }}
                                        />
                                        <Pagination
                                            className="mt-4"
                                            current={currentPage}
                                            pageSize={pageSize}
                                            total={totalItems}
                                            onChange={handleChangePage}
                                        />
                                    </>
                                ) : (
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="No data found"
                                    />
                                )}
                            </div>
                        )
                        }
                    </div>
                </div>
                <Modal
                    className="deleteModal"
                    title="Delete Property"
                    open={isModalOpen}
                    onCancel={handleCancel}
                    footer={false}
                >
                    <div className="iconRow">
                        <RxCrossCircled />
                    </div>
                    <div className="textRow">
                        <h3>Are you sure?</h3>
                        <p>Do you really want to delete this property?</p>
                    </div>
                    <div className="buttonRow">
                        <Button className="btnOutlined me-2" onClick={handleCancel}>Cancel</Button>
                        <Button className="btnFilled text-white" onClick={() => handleOk()}>Delete</Button>
                    </div>
                </Modal>
            </div>
            </div>
        </>
    );
};
export default AllProperties;